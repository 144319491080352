<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <form>
                        <div class="modal-body" v-if="evaluation.obj !== undefined">
                            <table class="table table-sm table-borderless" v-if="evaluation.employee !== undefined">
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td class="text-left" width="250px">
                                            <input type="checkbox" id="btc" value="1" :checked="evaluation.employee.company_id==1" :disabled="true"/> <label for="btc">Bangkok Travel Club Co.,Ltd.</label><br/>
                                            <input type="checkbox" id="bat" value="2" :checked="evaluation.employee.company_id==2" :disabled="true"/> <label for="bat">Bangkok Airtour (1988) Co.,Ltd.</label><br/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-center" colspan="2"><h3>แบบประเมินผลการปฏิบัติงานพนักงาน<br/>(Employee Performance Evaluation Form)</h3></td>
                                    </tr>
                                </tbody>
                            </table>
                            

                            <table class="table table-sm table-borderless" v-if="evaluation.employee !== undefined">
                                <tbody>
                                    <tr>
                                        <td width="10%">ชื่อ:<br/>Name</td>
                                        <td width="25%" class="align-middle" style="border-bottom:1px solid #ccc;">{{evaluation.employee.title}} {{evaluation.employee.firstname}} {{evaluation.employee.lastname}}</td>
                                        <td width="15%">รหัส :<br/>ID No.</td>
                                        <td width="15%" class="align-middle" style="border-bottom:1px solid #ccc;"><span v-if="evaluation !== undefined">{{evaluation.employee.code}}</span></td>
                                        <td width="10%">ตำแหน่ง :<br/>Position</td>
                                        <td class="align-middle" style="border-bottom:1px solid #ccc;"><span v-if="evaluation.employee.position !== undefined">{{evaluation.employee.position.name}}</span></td>
                                    </tr>
                                     <tr>
                                        <td>วันที่เริ่มงาน :<br/>Start Date</td>
                                        <td class="align-middle" style="border-bottom:1px solid #ccc;"><span v-if="evaluation !== undefined && evaluation.employee !== undefined">{{evaluation.employee.start_date}}</span></td>
                                        <td>ระยะเวลาการทำงาน :<br/>Employment Period</td>
                                        <td colspan="3" class="align-middle" style="border-bottom:1px solid #ccc;"><span v-if="evaluation.range !== undefined">{{evaluation.range.y}} year(s)</span></td>
                                    </tr>
                                </tbody>
                            </table>
                            
                            <table class="table table-bordered m-0">
                            <thead>
                                <tr>
                                    <th valign="top">ความรู้ ความสามารถ</th>
                                    <th width="15%" valign="top">ประเมินตนเอง</th>
                                    <th width="15%" valign="top">หัวหน้าประเมิน</th>
                                    <th width="15%" valign="top">ผู้จัดการประเมิน</th>
                                    <th width="15%" valign="top">กรรมการประเมิน</th>
                                </tr>
                                <tr>
                                    <th style="vertical-align: top;">Knowledge &amp; Work Ability</th>
                                    <th width="15%" style="vertical-align: top;">Self Assessment</th>
                                    <th width="15%" style="vertical-align: top;">Department Head Evaluation</th>
                                    <th width="15%" style="vertical-align: top;">GM Evaluation</th>
                                    <th width="15%" style="vertical-align: top;">MD Evaluation</th>
                                </tr>
                            </thead>
                            <tbody v-if="performances?.length > 0">
                                <tr v-for="obj in filteredPerformances(1)" :key="obj.id">
                                    <td>
                                        {{obj.id}}. {{obj.name_th}}<br/>{{obj.name_en}}
                                    </td>
                                    <td style="text-align:center">
                                        <select class="form-control" v-model="obj.self_score" >
                                            <option v-for="obj in ratings" :key="obj" v-bind:value="obj">{{ obj }}</option>
                                        </select>
                                    </td>
                                    <td style="text-align:center">
                                        
                                    </td>
                                    <td style="text-align:center">
                                        
                                    </td>
                                    <td style="text-align:center">
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="5"><b>ทัศนะคติต่อการทำงาน / Attitude Toward Working</b></td>
                                </tr>
                                <tr v-for="obj in filteredPerformances(2)" :key="obj.id">
                                    <td>
                                        {{obj.id}}. {{obj.name_th}}<br/>{{obj.name_en}}
                                    </td>
                                    <td style="text-align:center">
                                        <select class="form-control" v-model="obj.self_score" >
                                            <option v-for="obj in ratings" :key="obj" v-bind:value="obj">{{ obj }}</option>
                                        </select>
                                    </td>
                                    <td style="text-align:center">
                                        
                                    </td>
                                    <td style="text-align:center">
                                        
                                    </td>
                                    <td style="text-align:center">
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="5"><b>ระเบียบและความประพฤติ / Discipline and behavior</b></td>
                                </tr>
                                <tr v-for="obj in filteredPerformances(3)" :key="obj.id">
                                    <td>
                                        {{obj.id}}. {{obj.name_th}}<br/>{{obj.name_en}}
                                    </td>
                                    <td style="text-align:center">
                                        <select class="form-control" v-model="obj.self_score" >
                                            <option v-for="obj in ratings" :key="obj" v-bind:value="obj">{{ obj }}</option>
                                        </select>
                                    </td>
                                    <td style="text-align:center">
                                        
                                    </td>
                                    <td style="text-align:center">
                                        
                                    </td>
                                    <td style="text-align:center">
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>
                                    <b>คะแนนรวม / Total Score</b>
                                    </td>
                                    <td style="text-align:left">
                                        {{ totalSelf_score }} คะแนน / Scores
                                    </td>
                                    <td style="text-align:left"></td>
                                    <td style="text-align:center"></td>
                                    <td style="text-align:center"></td>
                                </tr>
                            </tfoot>
                            </table>
                            <br/>
                            <table class="table table-bordered m-0">
                                <tbody>
                                <tr>
                                    <td style="vertical-align: middle;"><b>Rating</b></td>
                                    <td align="center" width="15%">ดีมาก<br/>Excellent<br/>10/9</td>
                                    <td align="center" width="15%">ดี<br/>Good<br/>8/7</td>
                                    <td align="center" width="15%">ปานกลาง<br/>Fair<br/>6/5</td>
                                    <td align="center" width="15%">ควรปรับปรุง<br/>Improvement<br/>4/3</td>
                                    <td align="center" width="15%">ควรปรับปรุงอย่างยิ่ง<br/>Poor<br/>2/1</td>
                                </tr>
                                </tbody>
                            </table>
                            <div v-show="evaluation.obj.step >= 2">
                                <br/>
                                <table class="table table-bordered m-0">
                                    <tbody>
                                    <tr>
                                        <td align="center" width="25%" rowspan="11" style="vertical-align: middle;"><b>สรุปผลการประเมิน<br/>Evaluation Result</b></td>
                                        <td width="5%">1.</td>
                                        <td width="70%" align="left">
                                            <input type="checkbox" class="form-input" v-model="form.ck_10" :checked="form.ck_10==1"/> คะแนนถึง 80 ให้ถือว่าผ่านการทดลองงาน
                                            </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>
                                            <input type="checkbox" v-model="form.ck_11" :checked="form.ck_11==1" /> กรณีผลประเมินต่ำกว่า 70 คะแนน บริษัทมีสิทธิ์พิจารณา งดเว้นผลตอบแทนประจำปี
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>2.</td>
                                        <td>
                                            <input type="checkbox" v-model="form.ck_20" :checked="form.ck_20==1"/> บรรจุเป็นพนักงานประจำ ตั้งแต่วันที่ <input type="text" class="input-bottom-line" v-model="form.txt_20_date"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>3.</td>
                                        <td>
                                            <input type="checkbox" v-model="form.ck_30" :checked="form.ck_30==1"/> ขยายเวลาทดลองงาน <input type="text" class="input-bottom-line" v-model="form.txt_30_days"/> วัน
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            การปรับค่าจ้าง
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>
                                            <input type="checkbox" v-model="form.ck_salary" :checked="form.ck_salary==1" /> Salary&nbsp;&nbsp;&nbsp;
                                            <input type="checkbox" v-model="form.ck_fare" :checked="form.ck_fare==1" /> Fare&nbsp;&nbsp;&nbsp;
                                            <input type="checkbox" v-model="form.tel_charge" :checked="form.tel_charge==1" /> Telephone Charge
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>
                                            ปรับจาก <input type="text" class="input-bottom-line" v-model="form.old_amount"/> บาท / เดือน เป็น <input type="text" class="input-bottom-line" v-model="form.new_amount"/> บาท / เดือน
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>
                                            ปรับเงินเดือน มีผลตั้งแต่วันที่ <input type="text" class="input-bottom-line" v-model="form.effective_date"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>
                                            ไม่ปรับ อื่นๆ <input type="text" class="input-bottom-line" v-model="form.other"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>4.</td>
                                        <td>
                                            <input type="checkbox" v-model="form.ck_40" :checked="form.ck_40==1" /> ไม่ผ่านการทดลองงาน Probation failed
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>5.</td>
                                        <td>
                                            <input type="checkbox" v-model="form.ck_50" :checked="form.ck_50==1" /> ยุติการจ้าง Stop Employment ตั้งแต่วันที่ / Date <input type="text" class="input-bottom-line" v-model="form.txt_50_date"/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <table class="table table-borderless m-0">
                                <tbody>
                                <tr>
                                    <td align="left" width="25%">ความคิดเห็นเพิ่มเติม :<br/>Expressing opinion</td>
                                    <td align="left"><input type="text" class="input-bottom-line" v-model="form.expressing_opinion" style="width:100%;"/></td>
                                </tr>
                                </tbody>
                            </table>

                            <div v-show="evaluation.obj.step >= 2">
                                <br/>
                                <table class="table table-bordered m-0">
                                    <tbody>
                                    <tr>
                                        <td align="center" width="25%">ผู้ถูกประเมิน<br/>Appraisee</td>
                                        <td align="center" width="25%">หัวหน้าแผนก<br/>Department Head</td>
                                        <td align="center" width="25%">หัวหน้าส่วน<br/>General Manager</td>
                                        <td align="center" width="25%">กรรมการ<br/>Managing Director</td>
                                    </tr>
                                    <tr>
                                        <td style="height:50px"></td>
                                        <td>
                                            <div v-show="evaluation.obj.step >= '2' && ($gate.isManager() || $gate.isAdmin())">
                                            <input type="checkbox" v-model="form.ck_hod_approved" :checked="form.ck_hod_approved==1" @click="checkHod(1)"/> อนุมัติ / Approved<br/>
                                            <input type="checkbox" v-model="form.ck_hod_not_approved" :checked="form.ck_hod_not_approved==1" @click="checkHod(0)"/> ไม่อนุมัติ / Not Approved
                                            </div>
                                        </td>
                                        <td>
                                            <div v-show="evaluation.obj.step >= '2' && ($gate.isManager() || $gate.isAdmin())">
                                            <input type="checkbox" v-model="form.ck_gm_approved" :checked="form.ck_gm_approved==1" @click="checkGm(1)" /> อนุมัติ / Approved<br/>
                                            <input type="checkbox" v-model="form.ck_gm_not_approved" :checked="form.ck_gm_not_approved==1" @click="checkGm(0)"/> ไม่อนุมัติ / Not Approved
                                        </div>
                                        </td>
                                        <td>
                                            <div v-show="evaluation.obj.step >= '2' && ($gate.isManager() || $gate.isAdmin())">
                                            <input type="checkbox" v-model="form.ck_md_approved" :checked="form.ck_md_approved==1" @click="checkMd(1)"/> อนุมัติ / Approved<br/>
                                            <input type="checkbox" v-model="form.ck_md_not_approved" :checked="form.ck_md_not_approved==1" @click="checkMd(0)"/> ไม่อนุมัติ / Not Approved
                                        </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Signed <input type="text" class="input-bottom-line" /></td>
                                        <td>Signed <input type="text" class="input-bottom-line" /></td>
                                        <td>Signed <input type="text" class="input-bottom-line" /></td>
                                        <td>Signed <input type="text" class="input-bottom-line" /></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">
                                            ( {{evaluation.employee.firstname}} {{evaluation.employee.lastname}} )
                                            <br/>
                                            วันที่ / Date <span v-if="evaluation !== undefined">{{evaluation.created_at | moment}}</span>
                                        </td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        
                        <div class="modal-footer hidden-print">
                            <button @click.prevent="saveEvaluation" type="submit" class="btn btn-success">Save</button>
                            <button type="button" class="btn btn-secondary" @click="gotoDashboard">Cancel</button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
            </div>
        </div>   
    </section>    
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import moment from 'moment'

export default {
    components: {
          DatePicker,
    },
    data () {
        return  {
            editmode: true,
            evaluation: {},
            performances: [],
            ratings: [1,2,3,4,5,6,7,8,9,10],
            form: new Form({
                id : '',
                employee_id: 0,
                status: '00',
                evaluation_scores: [],
                ck_10: 0,
                ck_11: 0,
                ck_20: 0,
                txt_20_date: '',
                ck_30: 0,
                txt_30_days: '',
                ck_salary: 0,
                ck_fare: 0,
                tel_charge: 0,
                old_amount: '',
                new_amount: '',
                effective_date: '',
                other: '',
                ck_40: 0,
                ck_50: 0,
                expressing_opinion: '',
                hod_status: 0,
                hod_signed_date: '',
                gm_status: 0,
                gm_signed_date: '',
                md_status: 0,
                md_signed_date: '',
                step: 1,
                txt_50_date: '',

                self_signed_date: '', 

                ck_hod_approved: 0,
                ck_hod_not_approved: 0,

                ck_gm_approved: 0,
                ck_gm_not_approved: 0,

                ck_md_approved: 0,
                ck_md_not_approved: 0,
            }),
        }
    },
    mounted() {

    },
    methods: {
        saveEvaluation(){
            this.$Progress.start();
            this.form.employee_id = this.evaluation.employee.id,
            
            this.form.evaluation_scores = this.performances,
            this.form.post('/api/evaluations')
            .then((response) => {
                //console.log(response);
                this.$Progress.finish();
                this.$router.push('/evaluations')
            })
            .catch(function (error) {
                //console.log(error);
                this.$Progress.fail();
            });
        },
        gotoDashboard() {
            this.$router.push('/evaluations')
        }
        , filteredPerformances(group_id) {
            let tempRecipes = this.performances
            tempRecipes = tempRecipes.filter((item) => {
                return (item.group_id == group_id)
            })
            //console.log(tempRecipes);

            return tempRecipes;
        }
    },
    created() {

            this.$Progress.start();

            axios.get("/api/me/evaluation")
            .then((( response ) => {
                this.evaluation = response.data.data;
                this.performances = response.data.data.performances;

                //console.log(this.performances);
                this.form.step = this.evaluation.obj.step
                this.form.ck_10 = this.evaluation.obj.ck_10;
                this.form.ck_11 = this.evaluation.obj.ck_11;
                this.form.ck_20 = this.evaluation.obj.ck_20;
                this.form.txt_20_date = this.evaluation.obj.txt_20_date;
                this.form.ck_30 = this.evaluation.obj.ck_30;
                this.form.txt_30_days = this.evaluation.obj.txt_30_days;
                this.form.ck_salary = this.evaluation.obj.ck_salary;
                this.form.ck_fare = this.evaluation.obj.ck_fare;
                this.form.tel_charge = this.evaluation.obj.tel_charge;
                this.form.old_amount = this.evaluation.obj.old_amount;
                this.form.new_amount = this.evaluation.obj.new_amount;
                this.form.effective_date = this.evaluation.obj.effective_date;
                this.form.other = this.evaluation.obj.other;
                this.form.ck_40 = this.evaluation.obj.ck_40;
                this.form.ck_50 = this.evaluation.obj.ck_50;
                this.form.expressing_opinion = this.evaluation.obj.expressing_opinion;
                this.form.ck_50 = this.evaluation.obj.ck_50;
                this.form.txt_50_date = this.evaluation.obj.txt_50_date;

                this.form.step = this.evaluation.obj.step;
                this.form.hod_status = this.evaluation.obj.hod_status;
                this.form.hod_signed_date = this.evaluation.obj.hod_signed_date;

                this.form.gm_status = this.evaluation.obj.gm_status;
                this.form.gm_signed_date = this.evaluation.obj.gm_signed_date;
                
                this.form.md_status = this.evaluation.obj.md_status;
                this.form.md_signed_date = this.evaluation.obj.md_signed_date;

                if(this.form.hod_status === 1) {
                    this.form.ck_hod_approved = 1;
                }

                if(this.form.hod_status === 9) {
                    this.form.ck_hod_not_approved = 1;
                }

                if(this.form.gm_status === 1) {
                    this.form.ck_gm_approved = 1;
                }

                if(this.form.gm_status === 9) {
                    this.form.ck_gm_not_approved = 1;
                }

                if(this.form.md_status === 1) {
                    this.form.ck_md_approved = 1;
                }

                if(this.form.md_status === 9) {
                    this.form.ck_md_not_approved = 1;
                }

                
            }));
            
            this.$Progress.finish();
        },
    filters: {
        moment: function (date) {
            return moment(date).format('DD/MM/YYYY');
        },
        moment2: function (date) {
            return moment(date).format('YYYY-MM-DD, h:mm:ss');
        }
    },
    computed: {
        totalSelf_score() {

            return this.performances.reduce((acc, item) => acc + item.self_score, 0);
        }
    }
}
</script>

<style>
.input-bottom-line {
    border:none;border-bottom:1px solid #ccc;
}
</style>