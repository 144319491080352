<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                          <date-picker v-model="date_form" valueType="format" placeholder="From date"></date-picker>
                      </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                          <date-picker v-model="date_to" valueType="format" placeholder="To date"></date-picker>
                      </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                         <select class="form-control" v-model="employee_id">
                            <option value="">Select Employee</option>
                              <option 
                                  v-for="(cat,index) in employees" :key="index"
                                  :value="cat.id"
                                  :selected="index == employee_id">{{ cat.code }} : {{ cat.firstname }} {{ cat.lastname }}</option>
                            </select>
                      </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                          <button type="button" class="btn btn-primary" @click="searchAttendances">
                            <i class="fa fa-search"></i>
                            Search
                          </button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Attendance List</h3>

                <div class="card-tools">
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>
                  <button type="button" class="btn btn-sm btn-primary" @click="importModal">
                      <i class="fa fa-plus-square"></i>
                      Import
                  </button>
                  <button type="button" class="btn btn-sm btn-danger" @click="deleteSelected">
                      <i class="fa fa-trash"></i>
                      Delete
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th width="80px">
                        <label class="form-checkbox">
                        <input type="checkbox" v-model="selectAll" @click="select">
                        <i class="form-icon"></i>
                        </label>
                      </th>
                      <th>Employee</th>
                      <th width="120px">Date</th>
                      <th width="120px">Shift</th>
                      <th width="120px">Time In</th>
                      <th width="120px">Late</th>
                      <th width="120px">Time Out</th>
                      <th width="120px">Early</th>
                      <th style="width:100px">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="attendance in attendances.data" :key="attendance.id">
                      <td>
                        <label class="form-checkbox">
                          <input type="checkbox" :value="attendance.id" v-model="selected">
                          <i class="form-icon"></i>
                        </label>
                      </td>
                      <td>{{ attendance.code }} : {{attendance.firstname}} {{attendance.lastname}}</td>
                      <td>{{attendance.clock_date | moment}}</td>
                      <td>{{attendance.start_time}} - {{attendance.end_time}}</td>
                      <td>{{attendance.clock_time_in}}</td>
                      <td style="color:red">{{attendance.late | moment2}}</td>
                      <td>{{attendance.clock_time_out}}</td>
                      <td style="color:red">{{attendance.early | moment2}}</td>
                      <td>
                        <a href="#" @click="editModal(attendance)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        <a href="#" @click="delleteModal(attendance)">
                            <i class="fa fa-trash red"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="attendances" @pagination-change-page="getResults" :limit="10"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div v-if="!$gate.isAdmin()">
            <not-found></not-found>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New Attendance</h5>
                    <h5 class="modal-title" v-show="editmode">Update Attendance</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="editmode ? updateAttendance() : createAttendance()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="employee_id" >Employee</label>
                            <select id="employee_id" class="form-control" v-model="form.employee_id">
                              <option 
                                  v-for="(emp,index) in employees" :key="index"
                                  :value="emp.id"
                                  :selected="index == form.employee_id">{{ emp.code }} : {{ emp.firstname }} {{ emp.lastname }}</option>
                            </select>
                            <has-error :form="form" field="employee_id"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Date</label>
                            <date-picker id="clock_date" v-model="form.clock_date" valueType="format"></date-picker>
                            <has-error :form="form" field="clock_date"></has-error>
                        </div>
                        <div class="form-group">
                            <label for="clock_time_in">In</label>
                            <input id="clock_time_in" v-model="form.clock_time_in" type="text" name="clock_time_in" class="form-control" :class="{ 'is-invalid': form.errors.has('clock_time_in') }">
                            <has-error :form="form" field="clock_time_in"></has-error>
                        </div>
                        <div class="form-group">
                            <label for="clock_time_out">Out</label>
                            <input id="clock_time_out" v-model="form.clock_time_out" type="text" name="clock_time_out" class="form-control" :class="{ 'is-invalid': form.errors.has('clock_time_out') }">
                            <has-error :form="form" field="clock_time_out"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>

        <div class="modal fade" id="addImport" tabindex="-1" role="dialog" aria-labelledby="addImport" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Import Attendance</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="importAttendance()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>File</label>
                            <input type="file" name="filename" @change="selectFile" ref="inputFile">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-primary">Import</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import moment from 'moment';

    export default {
        components: {
          DatePicker,
        },
        data () {
            return {
                editmode: false,
                attendances : {},
                form: new Form({
                    id : '',
                    clock_date: '',
                    clock_time_in: '',
                    clock_time_out: '',
                    employee_id: '',
                }),
                employees: [],
                selected: [],
                selectAll: false,
                date_form: '',
                date_to: '',
                employee_id: '',
            }
        },
        methods: {
            searchAttendances() {
              this.loadAttendances();
            },
            getResults(page = 1) {
                  this.selectAll = false;
                  this.$Progress.start();
                  axios.get('/api/attendances/list?page=' + page + '&date_from='+this.date_form+'&date_to='+this.date_to+'&emp_id='+this.employee_id).then(({ data }) => (this.attendances = data.data));
                  this.$Progress.finish();
            },
            updateAttendance(){
                this.$Progress.start();
                this.form.put('/api/attendances/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                    //  Fire.$emit('AfterCreate');

                    this.loadAttendances();
                })
                .catch(() => {
                    this.$Progress.fail();
                });

            },
            editModal(attendance){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(attendance);
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },

            importModal() {
              this.$refs.inputFile.value = '';
              $('#addImport').modal('show');
            },
            loadAttendances(){
                this.selectAll = false;
                if(this.$gate.isAdmin()){
                    axios.get("/api/attendances/list?date_from="+this.date_form+'&date_to='+this.date_to+'&emp_id='+this.employee_id).then(({ data }) => (this.attendances = data.data));
                }
            },
            loadEmployees(){
              axios.get("/api/employees/list").then(({ data }) => (this.employees = data.data));
            },
            
            createAttendance(){
                this.form.post('/api/attendances')
                .then((response)=>{
                    $('#addNew').modal('hide');

                    Toast.fire({
                            icon: 'success',
                            title: response.data.message
                    });

                    this.$Progress.finish();
                    this.loadAttendances();
                })
                .catch(()=>{
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            },
            importAttendance() {
              this.form.post('/api/attendances/import')
                .then((response)=>{
                    $('#addImport').modal('hide');

                    Toast.fire({
                            icon: 'success',
                            title: response.data.message
                    });

                    this.$Progress.finish();
                    this.loadAttendances();
                })
                .catch(()=>{
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            },
            selectFile(e) {
              let file = e.target.files[0];
              let reader = new FileReader();  

              if(file['size'] < 2111775)
              {
                  reader.onloadend = (file) => {
                    this.form.document_ref = reader.result;
                  }              
                    reader.readAsDataURL(file);
              }else{
                  alert('File size can not be bigger than 2 MB')
              }
          },
            select() {
              this.selected = [];
              if (!this.selectAll) {
                for (let i in this.attendances.data) {
                  this.selected.push(this.attendances.data[i].id);
                }
              }
            }, 
            deleteSelected() {
              let obj = {
                attendanceIds: this.selected
              }
              axios.post('/api/attendances/delall', obj).then(res => {
                    this.loadAttendances();
              });
            }
        },
        mounted() {
            console.log('Component mounted.')
        },
        created() {
            this.date_form = new Date().toISOString().slice(0,8) + '01';
            this.date_to = new Date().toISOString().slice(0,10);

            this.$Progress.start();
            this.loadAttendances();
            this.loadEmployees();
            this.$Progress.finish();
        }, 
        filters: {
            moment: function (date) {
              return moment(date).format('DD-MM-YYYY');
            }, 
            moment2: function(hhmm) {
              if(moment.duration(hhmm).asMinutes() > 0) {
                return hhmm;

              } else {
                return '';
              }
            }
        }
    }
</script>
