<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header hidden-print">
                        <h3 class="card-title">Leave Details</h3>
                        <div class="card-tools">
                            <h3 v-if="leave.leave_status !== undefined">{{leave.leave_status.name}}</h3>
                        </div>
                    </div>

                    <div class="card-body">
                        <form>
                        <div class="modal-body">
                            <table class="table table-sm table-borderless" v-if="leave.employee !== undefined">
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td class="text-left" width="250px">
                                            <input type="checkbox" id="btc" value="1" :checked="leave.employee.company_id==1" :disabled="true"/> <label for="btc">Bangkok Travel Club Co.,Ltd.</label><br/>
                                            <input type="checkbox" id="bat" value="2" :checked="leave.employee.company_id==2" :disabled="true"/> <label for="bat">Bangkok Airtour (1988) Co.,Ltd.</label><br/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-center" colspan="2"><h3>ใบลางาน /  Leave Application Form</h3></td>
                                    </tr>
                                </tbody>
                            </table>
                            

                            <table class="table table-sm table-borderless" v-if="leave.employee !== undefined">
                                <tbody>
                                    <tr>
                                        <td>ชื่อ:<br/>Name</td>
                                        <td class="align-middle" style="border-bottom:1px solid #ccc;">{{leave.employee.title}} {{leave.employee.firstname}} {{leave.employee.lastname}}</td>
                                        <td>วันที่ :<br/>Date</td>
                                        <td class="align-middle" style="border-bottom:1px solid #ccc;"><span v-if="leave !== undefined">{{leave.created_at | moment}}</span></td>
                                    </tr>
                                     <tr>
                                        <td>ถึง/เรียน :<br/>To</td>
                                        <td class="align-middle" style="border-bottom:1px solid #ccc;"><span v-if="leave !== undefined && leave.employee !== undefined && leave.employee.manager !== undefined">{{leave.employee.manager.title}} {{leave.employee.manager.firstname}} {{leave.employee.manager.lastname}}</span></td>
                                        <td>ฝ่าย :<br/>Position</td>
                                        <td class="align-middle" style="border-bottom:1px solid #ccc;"><span v-if="leave.employee.position !== undefined">{{leave.employee.position.name}}</span></td>
                                    </tr>
                                    <tr>
                                        <td>ขอลาในวันที่ :<br/>Request for leave on</td>
                                        <td class="align-middle" style="border-bottom:1px solid #ccc;"><span v-if="leave !== undefined">{{leave.start_date | moment}}</span></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                            
                            <table class="table table-bordered m-0">
                            <thead>
                                <tr>
                                    <th>รายละเอียด (Detials)</th>
                                    <th>วันที่เริ่มลา (Start)</th>
                                    <th>วันที่สิ้นสุดการลา (Until)</th>
                                    <th>รวม (Total)</th>
                                    <th>ประวัติการลา (History)</th>
                                </tr>
                            </thead>
                            <tbody v-if="leave_summaries?.length > 0">
                                <tr v-for="obj in leave_summaries" :key="obj.id">
                                    <td>
                                        {{obj.description}}
                                    </td>
                                    <td style="text-align:left">
                                        <span v-if="obj.id === leave.leave_type_id">{{leave.start_date | moment}}</span>
                                    </td>
                                    <td style="text-align:left"><span v-if="obj.id === leave.leave_type_id">{{leave.end_date | moment}}</span></td>
                                    <td style="text-align:center"><span v-if="obj.id === leave.leave_type_id">{{leave.days}}</span></td>
                                    <td style="text-align:center">{{obj.used}}</td>
                                </tr>
                                <tr>
                                    <td>กลับมาทำงานวันที่<br/>Return to work</td>
                                    <td style="text-align:left"><span v-if="leave !== undefined">{{leave.return_date | moment}}</span></td>
                                    <td colspan="3"></td>
                                </tr>
                                <tr>
                                    <td>หมายเหตุ :<br/>Remark </td>
                                    <td colspan="4"><span v-if="leave !== undefined">{{leave.description}}</span></td>
                                </tr>
                                <tr>
                                    <td>ความเห็นเพิ่มเติม :<br/>Comments </td>
                                    <td colspan="4"><span v-if="leave !== undefined">{{leave.reason}}</span></td>
                                </tr>
                            </tbody>
                            <tbody v-if="leave_summaries?.length === 0">
                                <tr>
                                    <td colspan="4">No Data</td>
                                </tr>
                            </tbody>
                            </table>

                            <table class="table table-bordered m-0">
                                <tbody>
                                <tr>
                                    <td width="25%">ผู้ขออนุมัติ (Request By)</td>
                                    <td width="25%">หัวหน้าแผนก/Department Head</td>
                                    <td width="25%">หัวหน้าส่วน / GM</td>
                                    <td width="25%">กรรมการ / MD</td>
                                </tr>
                                <tr>
                                    <td>ลงชื่อ</td>
                                    <td>ลงชื่อ</td>
                                    <td>ลงชื่อ</td>
                                    <td>ลงชื่อ</td>
                                </tr>
                                <tr>
                                    <td class="text-center"><span v-if="leave !== undefined">{{leave.created_at | moment}}</span></td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        
                        <div class="modal-footer hidden-print">
                            <button v-show="leave.status === '00' || leave.status === '90'" @click.prevent="requestApprove" type="submit" class="btn btn-success">Submit to Approve</button>
                            <button v-show="leave.status === '01'" @click.prevent="cancelLeave" type="submit" class="btn btn-success">Cancel Leave</button>
                            <button v-show="leave.status === '10'" @click.prevent="cancelLeave" type="submit" class="btn btn-success">Cancel Approved Leave</button>
                            <button type="button" class="btn btn-secondary" @click="gotoDashboard">Back</button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
            </div>
        </div>   
    </section>    
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import moment from 'moment'

export default {
    components: {
          DatePicker,
    },
    data () {
        return  {
            editmode: true,
            leave: {},
            leave_summaries: {},
            form: new Form({
                id : '',
                employee_id: 1,
                leave_type_id: '',
                description: '',
                communication: '',
                status: '00',
                days: 1,
                start_date: '',
                start_period: 1,
                end_date: '',
                end_period: 1,
                return_date: '',
                reason: '',
            }),
        }
    },
    mounted() {

    },
    methods: {
        loadLeaveSummaries(){
            axios.get("/api/employees/leave-summaries/"+this.leave.employee_id).then(({ data }) => (this.leave_summaries = data.data));
        },
        requestApprove(){
              this.$Progress.start();
              this.form.status = '01';
              this.form.put('/api/leaves/'+this.form.id)
              .then((response) => {
                  this.$Progress.finish();
                  this.$router.push('/leaves')
              })
              .catch(() => {
                  this.$Progress.fail();
              });
          },
          requestCancelApprove(){
              this.$Progress.start();
              this.form.status = '02';
              this.form.put('/api/leaves/'+this.form.id)
              .then((response) => {
                  this.$Progress.finish();
                  this.$router.push('/leaves')
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
          cancelLeave(){
              this.$Progress.start();
              this.form.status = '91';
              this.form.put('/api/leaves/'+this.form.id)
              .then((response) => {
                  this.$Progress.finish();
                  this.$router.push('/leaves')
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
        
        gotoDashboard() {
            this.$router.push('/leaves')
        }
    },
    created() {

            this.$Progress.start();

            axios.get("/api/leaves/"+this.$route.params.id)
            .then((( response ) => {
                this.leave = response.data.data;
                this.form.fill(response.data.data);

                this.loadLeaveSummaries();
            }));
            
            this.$Progress.finish();
        },
        filters: {
            moment: function (date) {
                return moment(date).format('DD/MM/YYYY');
            },
            moment2: function (date) {
                return moment(date).format('YYYY-MM-DD, h:mm:ss');
            }
        },
}
</script>