<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <form @submit.prevent="importAttendance()">
                        <div class="form-group">
                            <label>File</label>
                            <input type="file" name="filename" @change="selectFile" ref="inputFile">
                        </div>
                        <button type="submit" class="btn btn-primary">Upload</button>
                  </form>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Import Preview</h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Code</th>
                      <th>Name</th>
                      <th>Department</th>
                      <th>Date</th>
                      <th>In</th>
                      <th>Out</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="attendance in attendances.data" :key="attendance.id">
                      <td>{{attendance.id}}</td>
                      <td>{{attendance.employee_id}} : {{ attendance.code }}</td>
                      <td>{{attendance.name}}</td>
                      <td>{{attendance.department}}</td>
                      <td>{{attendance.date}}</td>
                      <td>{{attendance.in}}</td>
                      <td>{{attendance.out}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
        </div>


        <div v-if="!$gate.isAdmin()">
            <not-found></not-found>
        </div>
    </div>
  </section>
</template>

<script>
    import DatePicker from 'vue2-datepicker';

    export default {
        components: {
          DatePicker,
        },
        data () {
            return {
                editmode: false,
                attendances : {},
                form: new Form({
                }),
                employees: [],
            }
        },
        methods: {
            importAttendance() {
              this.form.post('/api/attendances/import')
                .then((response)=>{
                    this.attendances = response.data
                    Toast.fire({
                            icon: 'success',
                            title: response.data.message
                    });

                    this.$Progress.finish();
                })
                .catch(()=>{
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            },
            selectFile(e) {
              let file = e.target.files[0];
              let reader = new FileReader();  

              if(file['size'] < 2111775)
              {
                  reader.onloadend = (file) => {
                    this.form.document_ref = reader.result;
                  }              
                    reader.readAsDataURL(file);
              }else{
                  alert('File size can not be bigger than 2 MB')
              }
          },
        },
        mounted() {
            console.log('Component mounted.')
        },
        created() {
            this.date_form = new Date().toISOString().slice(0,8) + '01';
            this.date_to = new Date().toISOString().slice(0,10);

            this.$Progress.start();
            this.$Progress.finish();
        }
    }
</script>
