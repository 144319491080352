
<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header p-2">
                            <h3 class="card-title">Profile</h3>
                        </div><!-- /.card-header -->
                        <div class="card-body">
                            <form  @submit.prevent="updateEmployee">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <label class="control-label">Employee ID: {{form_emp.code}}</label>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="control-label">Start: {{form_emp.start_date}}</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <input type="file" @change='upload_avatar' :class="{ 'is-invalid': form_emp.errors.has('avatar') }" name="avatar">
                                                <has-error :form="form_emp" field="avatar"></has-error> 
                                                <div class="avatar img-fluid img-circle" style="margin-top:10px">
                                                    <img v-if="form_emp.avatar !== ''" :src="get_avatar()" style="width:150px;height:150px"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-8">
                                        <div class="row m-3">
                                            <div class="col-sm-6">
                                                <label for="title" class="control-label">Title</label>
                                                <select name="title" v-model="form_emp.title" id="title" class="form-control" :class="{ 'is-invalid': form_emp.errors.has('title') }">
                                                    <option value="">Select Title</option>
                                                    <option value="Mr.">Mr.</option>
                                                    <option value="Mrs.">Mrs.</option>
                                                    <option value="Ms.">Ms.</option>
                                                </select>
                                                <has-error :form="form_emp" field="title"></has-error>
                                            </div>
                                            <div class="col-sm-6">
                                                <label for="nickname" class="control-label">Nickname</label>
                                                <input type="" v-model="form_emp.nickname" class="form-control" id="nickname" placeholder="Nickname" :class="{ 'is-invalid': form_emp.errors.has('nickname') }">
                                                <has-error :form="form_emp" field="nickname"></has-error>
                                            </div>
                                        </div>

                                        <div class="row m-3">
                                            <div class="col-sm-6">
                                                <label for="firstname" class="control-label">First name</label>
                                                <input type="" v-model="form_emp.firstname" class="form-control" id="firstname" placeholder="First name" :class="{ 'is-invalid': form_emp.errors.has('firstname') }">
                                                <has-error :form="form_emp" field="firstname"></has-error>
                                            </div>
                                            <div class="col-sm-6">
                                                <label for="lastname" class="control-label">Last name</label>
                                                <input type="" v-model="form_emp.lastname" class="form-control" id="lastname" placeholder="Last name" :class="{ 'is-invalid': form_emp.errors.has('lastname') }">
                                                <has-error :form="form_emp" field="lastname"></has-error>
                                            </div>
                                        </div>
                                        <div class="row m-3">
                                            <div class="col-sm-6">
                                                <label class="control-label">Position</label>
                                                <select class="form-control" v-model="form_emp.position_id">
                                                    <option 
                                                        v-for="(cat,index) in positions" :key="index"
                                                        :value="index"
                                                        :selected="index == form_emp.position_id">{{ cat }}</option>
                                                 </select>
                                                <has-error :form="form_emp" field="position_id"></has-error>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="control-label">Department</label>
                                                <select class="form-control" v-model="form_emp.department_id">
                                                <option 
                                                    v-for="(cat,index) in departments" :key="index"
                                                    :value="index"
                                                    :selected="index == form_emp.department_id">{{ cat }}</option>
                                                </select>
                                                <has-error :form="form_emp" field="department_id"></has-error>
                                            </div>
                                        </div>

                                        <div class="row m-3">
                                            <div class="col-sm-6">
                                                <label for="email" class="control-label">Email</label>
                                                <input type="" v-model="form_emp.email" class="form-control" id="email" placeholder="Email" :class="{ 'is-invalid': form_emp.errors.has('email') }">
                                                <has-error :form="form_emp" field="email"></has-error>
                                            </div>
                                            <div class="col-sm-6">
                                                <label for="telephone" class="control-label">Telephone</label>
                                                <input type="" v-model="form_emp.telephone" class="form-control" id="telephone" placeholder="Telephone" :class="{ 'is-invalid': form_emp.errors.has('telephone') }">
                                                <has-error :form="form_emp" field="telephone"></has-error>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row m-3">
                                    <div class="col-sm-12 text-right">
                                        <button type="submit" class="btn btn-success">Update</button>
                                        <button type="button" class="btn btn-secondary">Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div><!-- /.card-body -->
                    </div>
                    <!-- /.nav-tabs-custom -->
            </div>
            <!-- end tabs -->
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        data(){
            return {
                form_emp: new Form({
                    id:'',
                    user_id : '',
                    code: '',
                    title: '',
                    nickname: '',
                    firstname: '',
                    lastname: '',
                    email: '',
                    telephone: '',
                    department_id: 0,
                    position_id: 0,
                    company_id: 0,
                    manager_id: 0,
                    start_date: '',
                    avatar: '',
                }),
                departments: {},
                positions : {},
                companies : {},
                managers : {}
            }
        },
        mounted() {
            console.log('Component mounted.')
        },
        methods:{
            updateEmployee() {
                this.form_emp.put('/api/employees/'+this.form_emp.id)
                .then((data)=>{
                    this.$Progress.finish();
                    Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                })
                .catch((data) => {
                    this.$Progress.fail();

                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                });
            },
            upload_avatar(e){
              let file = e.target.files[0];
                let reader = new FileReader();  

                if(file['size'] < 2111775)
                {
                    reader.onloadend = (file) => {
                     this.form_emp.avatar = reader.result;
                    }              
                     reader.readAsDataURL(file);
                }else{
                    alert('File size can not be bigger than 2 MB')
                }
            },
            get_avatar(){
               let photo = (this.form_emp.avatar.length > 100) ? this.form_emp.avatar : "/images/profile/"+ this.form_emp.avatar;
               return photo;
            },

            loadDepartments(){
              axios.get("/api/departments/list").then(({ data }) => (this.departments = data.data));
            },
            loadPositions(){
                axios.get("/api/positions/list").then(({ data }) => (this.positions = data.data));
            },
            loadCompanies(){
                axios.get("/api/companies/list").then(({ data }) => (this.companies = data.data));
            },
            loadManagers(){
              axios.get("/api/employees/list").then(({ data }) => (this.managers = data.data));
            },
        },

        created() {
            this.$Progress.start();

            axios.get("/api/profile")
            .then((data) => {
                this.form_emp.fill(data.data.employee);
            });

            this.loadDepartments();
            this.loadPositions();
            this.loadCompanies();
            this.loadManagers();
            
            this.$Progress.finish();
        }
    }
</script>
