<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
            <form @submit.prevent="createCustomer()">
            <div class="card" v-if="$gate.isAdmin()">
              <div class="card-header">
                <h3 class="card-title">Create Customer</h3>
                <div class="card-tools">
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Customer Code</label>
                            <input v-model="form.code" type="text" name="code"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('code') }">
                            <has-error :form="form" field="code"></has-error>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Customer Name</label>
                            <input v-model="form.name" type="text" name="name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                            <has-error :form="form" field="name"></has-error>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Address</label>
                            <input v-model="form.address_line1" type="text" name="address_line1"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('address_line1') }">
                            <has-error :form="form" field="address_line1"></has-error>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>&nbsp;</label>
                            <input v-model="form.address_line2" type="text" name="address_line2"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('address_line2') }">
                            <has-error :form="form" field="address_line2"></has-error>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>City</label>
                            <input v-model="form.city" type="text" name="city"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('city') }">
                            <has-error :form="form" field="city"></has-error>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Country</label>
                            <select name="country_id" v-model="form.country_id" id="country_id" class="form-control" :class="{ 'is-invalid': form.errors.has('country_id') }">
                                <option :value={}>Please select one</option>
                                <option v-for="(cat,index) in countries" :key="index" :value="index" :selected="index == form.country_id">{{ cat }}</option>
                            </select>
                            <has-error :form="form" field="country_id"></has-error>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Postcode</label>
                            <input v-model="form.postcode" type="text" name="postcode"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('postcode') }">
                            <has-error :form="form" field="postcode"></has-error>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Telephone</label>
                            <input v-model="form.telephone" type="text" name="telephone"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('telephone') }">
                            <has-error :form="form" field="telephone"></has-error>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Fax</label>
                            <input v-model="form.Fax" type="text" name="Fax"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('Fax') }">
                            <has-error :form="form" field="Fax"></has-error>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Email</label>
                            <input v-model="form.email" type="text" name="email"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('email') }">
                            <has-error :form="form" field="email"></has-error>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Website</label>
                            <input v-model="form.website" type="text" name="website"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('website') }">
                            <has-error :form="form" field="website"></has-error>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label>Ticket Fee</label>
                            <input v-model="form.ticket_fee" type="text" name="ticket_fee"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('ticket_fee') }">
                            <has-error :form="form" field="ticket_fee"></has-error>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label>International</label>
                            <input v-model="form.ticket_fee_dom" type="text" name="ticket_fee_dom"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('ticket_fee_dom') }">
                            <has-error :form="form" field="ticket_fee_dom"></has-error>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label>Domestic</label>
                            <input v-model="form.ticket_fee_inter" type="text" name="ticket_fee_inter"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('ticket_fee_inter') }">
                            <has-error :form="form" field="ticket_fee"></has-error>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12">
                        <h4>Valunatry / Invalunatry</h4>
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                <th>Type</th>
                                <th>Transaction</th>
                                <th>International</th>
                                <th>Domestic</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td rowspan="2">Valunatry</td>
                                <td>Refund</td>
                                <td>
                                    <input v-model="form.val_refund_inter" type="text" name="val_refund_inter" class="form-control" :class="{ 'is-invalid': form.errors.has('val_refund_inter') }">
                                </td>
                                <td>
                                    <input v-model="form.val_refund_dom" type="text" name="val_refund_dom" class="form-control" :class="{ 'is-invalid': form.errors.has('val_refund_dom') }">
                                </td>
                                </tr>
                                <tr>
                                <td>Reissue/Revalidate</td>
                                <td>
                                    <input v-model="form.val_reissue_inter" type="text" name="val_reissue_inter" class="form-control" :class="{ 'is-invalid': form.errors.has('val_reissue_inter') }">
                                </td>
                                <td>
                                    <input v-model="form.val_reissue_dom" type="text" name="val_reissue_dom" class="form-control" :class="{ 'is-invalid': form.errors.has('val_reissue_dom') }">
                                </td>
                                </tr>
                                <tr>
                                <td rowspan="2">Invalunatry</td>
                                <td>Refund</td>
                                <td>
                                    <input v-model="form.inval_refund_inter" type="text" name="inval_refund_inter" class="form-control" :class="{ 'is-invalid': form.errors.has('inval_refund_inter') }">
                                </td>
                                <td>
                                    <input v-model="form.inval_refund_dom" type="text" name="inval_refund_dom" class="form-control" :class="{ 'is-invalid': form.errors.has('inval_refund_dom') }">
                                </td>
                                </tr>
                                <tr>
                                <td>Reissue/Revalidate</td>
                                <td>
                                    <input v-model="form.inval_reissue_inter" type="text" name="inval_reissue_inter" class="form-control" :class="{ 'is-invalid': form.errors.has('inval_reissue_inter') }">
                                </td>
                                <td>
                                    <input v-model="form.inval_reissue_dom" type="text" name="inval_reissue_dom" class="form-control" :class="{ 'is-invalid': form.errors.has('inval_reissue_dom') }">
                                </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <h4>Customer Contacts</h4>
                    </div>
                    <div class="col-sm-6 text-right">
                        <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                            <i class="fa fa-plus-square"></i>
                            Add New
                        </button>
                    </div>
                    <div class="col-sm-12">
                         <table class="table table-hover">
                            <thead>
                                <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Telephone</th>
                                <th>Email</th>
                                <th>Department</th>
                                <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="contact in contacts" :key="contact.id">
                                <td>{{contact.id}}</td>
                                <td>{{contact.contact_name}}</td>
                                <td>{{contact.telephone}}</td>
                                <td>{{contact.email}}</td>
                                <td>{{contact.department}}</td>
                                <td>
                                    <a href="#" @click="editModal(contact)">
                                        <i class="fa fa-edit blue"></i>
                                    </a>
                                    /
                                    <a href="#">
                                        <i class="fa fa-trash red" @click="deleteContact(contact)"></i>
                                    </a>
                                </td>
                                </tr>
                                <tr v-if="contacts.length == 0">
                                    <td colspan="6">No Data</td>
                                </tr>
                            </tbody>
                            </table>
                    </div>
                </div>

              </div>
                 <!-- /.card-body -->
                <div class="card-footer">
                    <button type="submit" class="btn btn-primary">Save</button>
                    <button type="button" class="btn btn-secondary">Cancel</button>
                </div>
            </div>
            <!-- /.card -->
            </form>

            <!-- Modal -->
            <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" v-show="!editmode">Add New Contact</h5>
                        <h5 class="modal-title" v-show="editmode">Update Contact</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <!-- <form @submit.prevent="createUser"> -->

                    <form @submit.prevent="editmode ? updateContact() : addContact()">
                        <p v-if="errors.length">
                            <b>Please correct the following error(s):</b>
                            <ul>
                            <li v-for="(error, index) in errors" v-bind:key="index">{{ error }}</li>
                            </ul>
                             </p>
                        <div class="modal-body">
                            <div class="form-group">
                                <label>Name <span class="text-danger">*</span></label>
                                <input v-model="form2.contact_name" type="text" name="contact_name"
                                    class="form-control" :class="{ 'is-invalid': form2.errors.has('contact_name') }">
                                <has-error :form="form2" field="contact_name"></has-error>
                            </div>
                            <div class="form-group">
                                <label>Email <span class="text-danger">*</span></label>
                                <input v-model="form2.email" type="text" name="email"
                                    class="form-control" :class="{ 'is-invalid': form2.errors.has('email') }">
                                <has-error :form="form2" field="email"></has-error>
                            </div>
                            <div class="form-group">
                                <label>Telephone <span class="text-danger">*</span></label>
                                <input v-model="form2.telephone" type="text" name="telephone"
                                    class="form-control" :class="{ 'is-invalid': form2.errors.has('telephone') }">
                                <has-error :form="form2" field="telephone"></has-error>
                            </div>
                            <div class="form-group">
                                <label>Department</label>
                                <input v-model="form2.department" type="text" name="department"
                                    class="form-control" :class="{ 'is-invalid': form2.errors.has('department') }">
                                <has-error :form="form2" field="department"></has-error>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                            <button v-show="!editmode" type="submit" class="btn btn-primary">Add</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </form>
                    </div>
                </div>
            </div>

          </div>

        </div>
    </div>
  </section>
</template>

<script>
    import DatePicker from 'vue2-datepicker';

    export default {
        components: {
          DatePicker,
        },
        data () {
            return {
                editmode: false,
                customer: {},
                form: new Form({
                    id : '',
                    code: '',
                    name: '',
                    address_line1 : '',
                    address_line2 : '',
                    city: '',
                    country_id: '',
                    postcode: '',
                    phone: '',
                    fax: '',
                    email: '',
                    website: '',
                    ticket_fee_dom: '',
                    ticket_fee_inter: '',
                    val_refund_inter: '',
                    val_refund_dom: '',
                    val_reissue_dom: '',
                    val_reissue_inter: '',
                    inval_refund_inter: '',
                    inval_refund_dom: '',
                    inval_reissue_dom: '',
                    inval_reissue_inter: '',
                    contacts : [],
                    
                }),
                form2 : new Form({
                    id: 0,
                    contact_name: '',
                    telephone: '',
                    email: '',
                    department: '',
                }),
                countries : {},
                contacts : [],
                errors: [],
            }
        },
        methods: {
            loadCountries(){
                axios.get("/api/contries/list").then(({ data }) => (this.departments = data.data));
            },
            createCustomer(){
                this.form.contacts = this.contacts;
                this.form.post('/api/customers')
                .then((response)=>{
                    Toast.fire({
                            icon: 'success',
                            title: response.data.message
                    });

                    this.$Progress.finish();

                })
                .catch(()=>{
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            }, 
            editModal(contact){
                this.errors = [];
                this.editmode = true;
                this.form2.reset();
                $('#addNew').modal('show');
                this.form2.fill(contact);
            },
            newModal(){
                this.errors = [];
                this.editmode = false;
                this.form2.reset();
                
                if(this.contacts.length == 0) {
                    this.form2.id = 1;

                } else {
                    this.form2.id = this.contacts[this.contacts.length-1].id+1;
                }

                $('#addNew').modal('show');
            },
            addContact(){
                this.errors = [];
                if (!this.form2.contact_name) {
                    this.errors.push("Name required.");
                }
                if (!this.form2.email) {
                    this.errors.push('Email required.');
                }

                if (!this.form2.telephone) {
                    this.errors.push('Telephone required.');
                }

                if (!this.errors.length) {
                const obj = {
                    id: this.form2.id,
                    contact_name: this.form2.contact_name,
                    email: this.form2.email,
                    telephone: this.form2.telephone,
                    department: this.form2.department,
                };
                this.contacts.push(obj);

                $('#addNew').modal('hide');
                }
            },
            updateContact(){
                this.errors = [];
                if (!this.form2.contact_name) {
                    this.errors.push("Name required.");
                }
                if (!this.form2.email) {
                    this.errors.push('Email required.');
                }

                if (!this.form2.telephone) {
                    this.errors.push('Telephone required.');
                }

                if (!this.errors.length) {
                const obj = {
                    id: this.form2.id,
                    contact_name: this.form2.contact_name,
                    email: this.form2.email,
                    telephone: this.form2.telephone,
                    department: this.form2.department,
                };

                const index = this.contacts.findIndex(item => {
                    return (obj.id === item.id)
                })
                this.contacts.splice(index, 1, obj)

                $('#addNew').modal('hide');
                }
            },
            deleteContact(emp) {
                const filtersList = this.contacts.filter(element => element !== emp)
                this.contacts=filtersList
            }

        },
        mounted() {
            //console.log('User Component mounted.')
        },
        created() {
            this.$Progress.start();
            this.loadCountries();

            axios.get("/api/customers/"+this.$route.params.id)
            .then((( response ) => {
                this.customer = response.data.data;
                this.contacts = response.data.data.contacts;
                this.form.fill(response.data.data);
            }));

            this.$Progress.finish();
        }
    }
</script>
