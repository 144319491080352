<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
            <div class="card no-print">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <select class="form-control" v-model="year">
                          <option value="">Select Year</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                          </select>
                      </div>
                  </div>
                  
                  <div class="col-md-3" v-show="$gate.isAdmin()">
                    <div class="form-group">
                         <select class="form-control" v-model="employee_id">
                            <option value="">Select Employee</option>
                              <option 
                                  v-for="(cat,index) in employees" :key="index"
                                  :value="cat.id"
                                  :selected="index == employee_id">{{ cat.code }} : {{ cat.firstname }} {{ cat.lastname }}</option>
                            </select>
                      </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                          <button type="button" class="btn btn-primary" @click="searchAttendances">
                            <i class="fa fa-search"></i>
                            Search
                          </button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header no-print">
                <h3 class="card-title"></h3>
                <div class="card-tools">
                  <button type="button" class="btn btn-primary no-print" v-show="$gate.isAdmin()" onclick="window.print()">
                      <i class="fa fa-print"></i>
                      Print
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <td colspan="4" align="center"><h5>Yearly Summery Report</h5></td>
                    </tr>
                    <tr>
                      <th width="150px">Employee ID</th>
                      <td colspan="3">{{ attendances.code }}</td>
                    </tr>
                    <tr>
                      <th width="150px">Name</th>
                      <td colspan="3">{{ attendances.name }}</td>
                    </tr>
                    <tr>
                      <th width="150px">Position</th>
                      <td colspan="3">{{ attendances.position }}</td>
                    </tr>
                    <tr>
                      <th width="150px">Department</th>
                      <td colspan="3">{{ attendances.department }}</td>
                    </tr>
                    <tr>
                      <th width="150px">Month</th>
                      <th width="100px">Late Days</th>
                      <th width="100px">Sick Leaves</th>
                      <th>Sick Leave Details</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="attendance in attendances.items" :key="attendance.id">
                      <td>{{attendance.month }}</td>
                      <td style="text-align:center;">{{attendance.lates}}</td>
                      <td style="text-align:center;">{{attendance.sicks}}</td>
                      <td style="text-align:left;">
                        <li v-for="obj in attendance.sick_detais" :key="obj.id">
                          {{ obj.date | moment }} {{ obj.description }}
                        </li>

                        <table border="0" cellpading="0" cellspacing="0" class="details" style="display:none">
                          <tr v-for="obj in attendance.sick_detais" :key="obj.id" class="no-border">
                            <td class="no-border" width="100px" style="border:none;padding:0px 0px 0px 0px">{{ obj.date | moment }}</td>
                            <td class="no-border" style="border:none;padding:0px 0px 0px 5px">{{ obj.description }}</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td width="150px">TOTAL</td>
                      <td width="100px" style="text-align:center;">{{attendances.total_lates}}</td>
                      <td width="100px" style="text-align:center;">{{attendances.total_sicks}}</td>
                      <td style="text-align:left;"></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
        </div>
    </div>
  </section>
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import moment from 'moment';

    export default {
        components: {
          DatePicker,
        },
        data () {
            return {
                editmode: false,
                attendances : {},
                form: new Form({
                    id : '',
                    clock_date: '',
                    clock_time_in: '',
                    clock_time_out: '',
                    employee_id: '',
                }),
                employees: [],
                year: '2024',
                employee_id: '',
            }
        },
        methods: {
            searchAttendances() {
              this.$Progress.start();
              this.loadAttendances();
              this.$Progress.finish();
            },
            loadAttendances(){
                axios.get("/api/reports/annual?year="+this.year+'&emp_id='+this.employee_id).then(({ data }) => (this.attendances = data.data));
            },
            loadEmployees(){
              axios.get("/api/employees/list").then(({ data }) => (this.employees = data.data));
            },
        },
        mounted() {
        },
        created() {
            this.$Progress.start();
            this.loadAttendances();
            this.loadEmployees();
            this.$Progress.finish();
        }, 
        filters: {
            moment: function (date) {
              return moment(date).format('DD-MMM-YYYY');
            }, 
            moment2: function(hhmm, max) {
              if(moment.duration(hhmm).asMinutes() > max) {
                return hhmm;

              } else {
                return '';
              }
            },
            moment3: function(day_of_week) {
              if(day_of_week == 1) {
                return "Day Off"
              }
              if(day_of_week == 7) {
                return "Day Off"
              }

              return '';
            }
        }
    }
</script>

<style>
@media print{
    @page {
        size: portrait
    }

    .card-title {
      text-align: center;
    }
  }
</style>