<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card" v-if="$gate.isAdmin()">
              <div class="card-header">
                <h3 class="card-title">Leave Summaries</h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-bordered table-hover" v-if="result.privot !== undefined">
                  <thead>
                    <tr>
                      <th rowspan="2">#</th>
                      <th rowspan="2">Name</th>
                      <template v-for="obj in result.leave_types">
                      <th style="text-align:center" colspan="3" :key="obj.id">{{obj.name}}</th>
                      </template>
                    </tr>
                    <tr>
                      <template v-for="obj in result.privot[0].leave_summaries">
                      <th :key="obj.key1">Total</th>
                      <th :key="obj.key2">Used</th>
                      <th :key="obj.key3">Balance</th>
                      </template>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="obj in result.privot" :key="obj.id">
                        <td>{{obj.employee.id}}</td>
                        <td class="text-capitalize">{{obj.employee.firstname}}</td>
                        <template v-for="obj2 in obj.leave_summaries">
                        <td style="text-align:center" :key="obj2.key1">
                            {{obj2.total}}
                        </td>
                        <td style="text-align:center" :key="obj2.key2">
                            {{obj2.used}}
                        </td>
                        <td style="text-align:center" :key="obj2.key3">
                            {{obj2.balance}}
                        </td>
                        </template>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                 
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>


        <div v-if="!$gate.isAdmin()">
            <not-found></not-found>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New Leave Type</h5>
                    <h5 class="modal-title" v-show="editmode">Update Leave Type</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="editmode ? updateCategory() : createCategory()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Name</label>
                            <input v-model="form.name" type="text" name="name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                            <has-error :form="form" field="name"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Description</label>
                            <textarea v-model="form.description" name="description"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('description') }"></textarea>
                            <has-error :form="form" field="description"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Maximum Day</label>
                            <input v-model="form.max_day" type="text" name="max_day"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('max_day') }">
                            <has-error :form="form" field="max_day"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Include Weekend</label>
                            <select class="form-control" v-model="form.include_weekend" name="include_weekend">
                              <option value="0">No</option>
                              <option value="1">Yes</option>
                            </select>
                            <has-error :form="form" field="include_weekend"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    export default {
        data () {
            return {
                editmode: false,
                result : {},
                form: new Form({
                    id : '',
                    name: '',
                    description: '',
                    max_day: 0,
                    include_weekend: 0,
                    status: 1,
                })
            }
        },
        methods: {

            getResults(page = 1) {
                  this.$Progress.start();
                  
                  axios.get('/api/leaveTypes?page=' + page).then(({ data }) => (this.leaveTypes = data.data));

                  this.$Progress.finish();
            },
            updateCategory(){
                this.$Progress.start();
                this.form.put('/api/leaveTypes/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');

                    this.loadCategories();
                })
                .catch(() => {
                    this.$Progress.fail();
                });

            },
            editModal(leaveType){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(leaveType);
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },

            loadCategories(){
                if(this.$gate.isAdmin()){
                    axios.get("/api/leave-summaries").then(({ data }) => (this.result = data.data));
                }
            },
            
            createCategory(){

                this.form.post('/api/leaveTypes')
                .then((response)=>{
                    $('#addNew').modal('hide');

                    Toast.fire({
                            icon: 'success',
                            title: response.data.message
                    });

                    this.$Progress.finish();
                    this.loadCategories();
                })
                .catch(()=>{
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            }

        },
        mounted() {
            console.log('Component mounted.')
        },
        created() {

            this.$Progress.start();
            this.loadCategories();
            this.$Progress.finish();
        }
    }
</script>
