<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">
          <div class="col-4">
              <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Leaves Summaries</h3>
                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table table-hover">
                          <thead>
                          <tr>
                            <th>Leave Type</th>
                            <th>Total</th>
                            <th>Used</th>
                            <th>Remain</th>
                          </tr>
                        </thead>
                        <tbody>
                            <tr v-for="user in leave_summaries" :key="user.id">
                              <td>
                                {{user.name}}
                              </td>
                              <td> {{user.total}}</td>
                              <td> {{user.used}}</td>
                              <td> {{user.balance}}</td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                  </div>
          </div>
          <div class="col-8">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Leave List</h3>

                <div class="card-tools">
                  
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Leave Type</th>
                      <th>Days</th>
                      <th>Return to work</th>
                      <th>Request Date</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="leave in leaves.data" :key="leave.id">
                      <td>
                        <a v-bind:href="'/uploads/'+ leave.document_ref" v-if="leave.document_ref != null" target="_blank">
                            <i class="fa fa-file fa-lg red" aria-hidden="true"></i>
                        </a>
                        {{leave.leave_type.name}}
                        <br/>
                        {{leave.start_date | moment}} to {{leave.end_date | moment}}
                      </td>
                      <td>{{leave.days}} Days</td>
                      <td>{{leave.return_date}}</td>
                      <td>{{leave.created_at | moment2}}</td>
                      <td>
                        <span v-show="leave.status == '00'" class="badge badge-info p-10">{{leave.leave_status.name}}</span>
                        <span v-show="leave.status == '01'" class="badge badge-warning">{{leave.leave_status.name}}</span>
                        <span v-show="leave.status == '02'" class="badge badge-warning">{{leave.leave_status.name}}</span>
                        <span v-show="leave.status == '10'" class="badge badge-success">{{leave.leave_status.name}}</span>
                        <span v-show="leave.status == '90'" class="badge badge-danger">{{leave.leave_status.name}}</span>
                        <span v-show="leave.status == '91'" class="badge badge-danger">{{leave.leave_status.name}}</span>
                        <span v-show="leave.status == '92'" class="badge badge-danger">{{leave.leave_status.name}}</span>
                      </td>
                      <td class="text-left" style="width:100px">
                        <a v-bind:href="'/leaves/'+ leave.id" class="btn btn-outline-info btn-sm" title="Details">
                            <i class="fa fa-paper-plane" aria-hidden="true"></i>
                        </a>
                        <a v-show="leave.status == '00' || leave.status == '90'" href="#" class="btn btn-outline-info btn-sm" @click="editModal(leave)" title="Edit">
                            <i class="fa fa-edit"></i>
                        </a>
                        <a v-show="leave.status == '00'" href="#" class="btn btn-outline-info btn-sm" @click="deleteLeave(leave.id)" title="Delete">
                            <i class="fa fa-trash"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="leaves" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New Leave</h5>
                    <h5 class="modal-title" v-show="editmode">Edit Leave</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateLeave() : createLeave()" enctype="multipart/form-data">
                    <div class="modal-body">
                      <div class="form-group" style="background-color:#f5c542;padding:10px">
                        Leaves Request : {{form.days}} Days
                      </div>
                      <div class="form-group">
                            <label>Leave Type <span class="text-danger">*</span></label>
                            <select class="form-control" :class="{ 'is-invalid': form.errors.has('leave_type_id') }" v-model="form.leave_type_id" @change="loadBalance()">
                              <option 
                                  v-for="(cat,index) in leaveTypes" :key="index"
                                  :value="index"
                                  :selected="index == form.leave_type_id">{{ cat }}</option>
                            </select>
                            <has-error :form="form" field="leave_type_id"></has-error>
                        </div>

                        <div class="form-group">
                            <label>Description</label>
                            <textarea v-model="form.description" name="description"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('description') }"></textarea>
                            <has-error :form="form" field="description"></has-error>
                        </div>
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="form-group">
                                <label>Start Date <span class="text-danger">*</span></label>
                                <date-picker v-model="form.start_date" valueType="format" @change="someEvent()" :class="{ 'is-invalid': form.errors.has('start_date') }"></date-picker>
                                <has-error :form="form" field="start_date"></has-error>
                            </div>
                            </div>
                            <div class="col-sm-6">
                        <div class="form-group">
                            <label>Start Period <span class="text-danger">*</span></label>
                            <select class="form-control" v-model="form.start_period" @change="someEvent()" >
                              <option value="1">Full</option>
                              <option value="2">Haft Morning</option>
                              <option value="3">Haft Afternoon</option>
                            </select>
                            <has-error :form="form" field="start_period"></has-error>
                        </div>
                        </div>
                          </div>
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="form-group">
                            <label>End Date <span class="text-danger">*</span></label>
                            <date-picker v-model="form.end_date" valueType="format" :disabled-date="disabledBeforeTodayAndAfterAWeek" @change="someEvent()" :class="{ 'is-invalid': form.errors.has('end_date') }"></date-picker>
                            <has-error :form="form" field="end_date"></has-error>
                        </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                            <label>End Period</label>
                            <select class="form-control" v-model="form.end_period" @change="someEvent()">
                              <option value="1">Full</option>
                              <option value="2">Haft Morning</option>
                              <option value="3">Haft Afternoon</option>
                            </select>
                            <has-error :form="form" field="end_period"></has-error>
                        </div>
                          </div>
                        </div>
                        
                         <div class="row">
                          <div class="col-sm-6">
                            <div class="form-group">
                                <label>Return to work <span class="text-danger">*</span></label>
                                <date-picker v-model="form.return_date" valueType="format" :class="{ 'is-invalid': form.errors.has('return_date') }"></date-picker>
                                <has-error :form="form" field="return_date"></has-error>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                                <label>Document</label>
                                <input type="file" name="filename" @change="selectFile" ref="inputFile">
                            </div>
                          </div>
                         </div>
                    </div>
                    <div class="modal-footer">
                        <button v-show="editmode" type="submit" class="btn btn-success">Save</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Save</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import moment from 'moment'

    export default {
      components: {
          VueTagsInput,
          DatePicker,
        },
        data () {
            return {
                editmode: false,
                leaves : {},
                leave_summaries : {},
                form: new Form({
                    id : '',
                    employee_id: this.$userId,
                    leave_type_id: '',
                    description: '',
                    communication: '',
                    status: '00',
                    days: 0,
                    start_date: '',
                    start_period: 1,
                    end_date: '',
                    end_period: 1,
                    return_date: '',
                    reason: '',
                    vmode: 0,
                    document_ref: '',
                }),
                leaveTypes: {},
                autocompleteItems: {},
            }
        },
        methods: {
          progress(use, total) {
            if(total == 0) {
              return 100;
            }

            return use*100/total;
          },
          getResults(page = 1) {

              this.$Progress.start();
              
              axios.get('/api/leaves?page=' + page).then(({ data }) => (this.leaves = data.data));

              this.$Progress.finish();
          },
          loadLeaves(){

            // if(this.$gate.isAdmin()){
              axios.get("/api/leaves").then(({ data }) => (this.leaves = data.data));
            // }
          },
          loadLeaveTypes(){
              axios.get("/api/leaveTypes/list").then(({ data }) => (this.leaveTypes = data.data));
          },
          loadSummaries(){
                axios.get("/api/me/leave-summaries").then(({ data }) => (this.leave_summaries = data.data));
          },
          selectFile(e) {
              let file = e.target.files[0];
              let reader = new FileReader();  

              if(file['size'] < 2111775)
              {
                  reader.onloadend = (file) => {
                    this.form.document_ref = reader.result;
                  }              
                    reader.readAsDataURL(file);
              }else{
                  alert('File size can not be bigger than 2 MB')
              }
          },
          loadBalance() {
            let items = this.leave_summaries;

            items.forEach((item, index) => {
              if(item.id == this.form.leave_type_id) {
                //console.log(item.name +'==>'+item.balance);
              }
            })
          },
          someEvent() {
            this.form.vmode = 1;
            this.form.post('/api/leaves/calculate_days')
              .then((data)=>{
                if(data.data.success){
                  this.form.days = data.data.data.days;

                  if(data.data.data.return_date != '') {
                    this.form.return_date = data.data.data.return_date.date;
                  }
                }
              }
              );
          },
          disabledBeforeTodayAndAfterAWeek(date) {
            const today = new Date(this.form.start_date);
            today.setHours(0, 0, 0, 0);

            return date < today;
          },
          editModal(leave){
              this.editmode = true;
              this.form.reset();
              this.$refs.inputFile.value = '';
              $('#addNew').modal('show');
              this.form.fill(leave);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              //this.form.start_date = '2023-01-01';
              this.$refs.inputFile.value = '';
              $('#addNew').modal('show');
          },
          createLeave(){
              let valid = true;
              this.leave_summaries.forEach((item, index) => {
                if(item.id == this.form.leave_type_id) {
                  if(this.form.days > item.balance && item.max_day > 0) {
                    alert('Insufficient balance');
                    valid = false;
                  }
                }
              })

              if(valid) {
                this.$Progress.start();
                this.form.vmode = 0;
                this.form.post('/api/leaves')
                  .then((data)=>{
                    if(data.data.success){
                      $('#addNew').modal('hide');

                      Toast.fire({
                            icon: 'success',
                            title: data.data.message
                        });
                      this.$Progress.finish();
                      //this.loadLeaves();

                      this.$router.push('/leaves/'+data.data.data.id);

                    } else {
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });

                      this.$Progress.failed();
                    }
                  })
                  .catch(()=>{
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
              }
          },
          updateLeave(){
              this.$Progress.start();
              this.form.put('/api/leaves/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');
                  //this.$router.push('/leaves/'+data.data.data.id);

                  this.loadLeaves();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
          deleteLeave(id){
              Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this!",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Yes, delete it!'
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('/api/leaves/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted!',
                                      'Your file has been deleted.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadLeaves();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },

        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            this.loadSummaries();
            this.loadLeaves();
            this.loadLeaveTypes();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
            moment: function (date) {
                return moment(date).format('DD MMMM YYYY');
            },
            moment2: function (date) {
                return moment(date).format('YYYY-MM-DD');
            }
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
    }
</script>
