<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row ">
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon bg-info elevation-1"><i class="fas fa-cog"></i></span>

                        <div class="info-box-content">
                            <span class="info-box-text"><a v-bind:href="'/leaves/all?status=request'">Request</a></span>
                            <span class="info-box-number">
                            {{this.requests}}
                            </span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon bg-success elevation-1"><i class="fa fa-check"></i></span>

                        <div class="info-box-content">
                            <span class="info-box-text"><a v-bind:href="'/leaves/all?status=approved'">Approved</a></span>
                            <span class="info-box-number">{{this.approved}}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->

                <!-- fix for small devices only -->
                <div class="clearfix hidden-md-up"></div>

                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon bg-danger elevation-1"><i class="fa fa-times"></i></span>

                        <div class="info-box-content">
                            <span class="info-box-text"><a v-bind:href="'/leaves/all?status=rejected'">Rejected</a></span>
                            <span class="info-box-number">{{this.rejected}}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
            <!-- /.col -->
            </div>
            <!-- /.row -->

            <div class="row">
                <!-- Left col -->
                <div class="col-md-12">
                    <!-- MAP & BOX PANE -->
                    
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <select class="form-control" v-model="employee_id">
                                        <option value="">Select Employee</option>
                                        <option 
                                            v-for="(cat,index) in employees" :key="index"
                                            :value="cat.id"
                                            :selected="index == employee_id">{{ cat.firstname }} {{ cat.lastname }}</option>
                                        </select>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <select class="form-control" v-model="leave_type_id">
                                        <option value="">Select Leave Type</option>
                                        <option 
                                            v-for="(cat,index) in leaveTypes" :key="index"
                                            :value="index"
                                            :selected="index == leave_type_id">{{ cat }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <button type="button" class="btn btn-primary" @click="loadLeaves">
                                        <i class="fa fa-search"></i>
                                        Search
                                    </button>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                      <div class="card-header">
                          <h3 class="card-title">Leaves</h3>
                      </div>
                      <!-- /.card-header -->
                      <div class="card-body p-0">
                          <div class="table-responsive">
                          <table class="table m-0">
                              <thead>
                                  <tr>
                                      <th width="250">Name</th>
                                      <th width="250">Leave Type</th>
                                      <th>Description</th>
                                      <th width="100">Days</th>
                                      <th width="100">Request</th>
                                      <th width="150">Status</th>
                                  </tr>
                              </thead>
                              <tbody v-if="leaves.data?.length > 0">
                                  <tr v-for="leave in leaves.data" :key="leave.id">
                                      <td>{{leave.employee.firstname}} {{leave.employee.lastname}}</td>
                                      <td>
                                          <a v-bind:href="'/uploads/'+ leave.document_ref" v-if="leave.document_ref != null" target="_blank">
                                              <i class="fa fa-file fa-lg red" aria-hidden="true"></i>
                                          </a>
                                          {{leave.leave_type.name}}
                                          <br/>
                                          {{leave.start_date | moment}} to {{leave.end_date | moment}}
                                      </td>
                                      <td>{{leave.description}}</td>
                                      <td>{{leave.days}} Days</td>
                                      <td>{{leave.created_at | moment2}}</td>
                                      <td>
                                          <span v-show="leave.status == '00'" class="badge badge-info p-10">{{leave.leave_status.name}}</span>
                                          <span v-show="leave.status == '01'" class="badge badge-warning">{{leave.leave_status.name}}</span>
                                          <span v-show="leave.status == '02'" class="badge badge-warning">{{leave.leave_status.name}}</span>
                                          <span v-show="leave.status == '10'" class="badge badge-success">{{leave.leave_status.name}}</span>
                                          <span v-show="leave.status == '90'" class="badge badge-danger">{{leave.leave_status.name}}</span>
                                          <span v-show="leave.status == '91'" class="badge badge-success">{{leave.leave_status.name}}</span>
                                          <span v-show="leave.status == '92'" class="badge badge-success">{{leave.leave_status.name}}</span>
                                      </td>
                                  </tr>
                              </tbody>
                              <tbody v-if="leaves.data?.length === 0">
                                  <tr>
                                      <td colspan="6">No Data</td>
                                  </tr>
                              </tbody>
                          </table>
                          </div>
                      </div>
                  <!-- /.card-body -->
                      <div class="card-footer">
                      <div class="row">
                          <div class="col-md-6" style="padding-top:5px">Showing {{leaves.from}} to {{leaves.to}} of {{leaves.total}} entries</div>
                          <div class="col-md-6">
                              <pagination :data="leaves" :limit="5" :keepLength="true" @pagination-change-page="getResults" align="right">
                                <span slot="prev-nav">&lt; Previous</span>
	                              <span slot="next-nav">Next &gt;</span>
                              </pagination>
                          </div>
                      </div>
                    </div>
                    </div>
                <!-- /.card -->
                <!-- /.card -->
                </div>
            <!-- /.col -->
            </div>
            <!-- /.row -->
        </div><!--/. container-fluid -->
    </section>
</template>

<script>
    import moment from 'moment';

    export default {
        data () {
            return  {
                rule: '',
                leaves : {},
                requests: 0,
                approved: 0,
                rejected: 0,
                employees: {},
                employee_id: '',
                leaveTypes: {},
                leave_type_id: ''
            }
        },
        methods : {
            getResults(page = 1) {
              let status = this.$route.query.status;
                if(status == undefined) {
                  status = 'all';
                }

              this.$Progress.start();
              axios.get('/api/leaves/list?page=' + page +'&status='+status +'&employee_id='+this.employee_id+'&leave_type_id='+this.leave_type_id).then(({ data }) => (this.leaves = data.data));
              this.$Progress.finish();
            },
            loadLeaves(){
                let status = this.$route.query.status;
                if(status == undefined) {
                  status = 'all';
                }

                //console.log(status);
                axios.get("/api/leaves/list?status="+status +'&employee_id='+this.employee_id+'&leave_type_id='+this.leave_type_id).then(({ data }) => {
                    this.leaves = data.data;
                });
            },
            loadDashboard(){
                axios.get("/api/leaves/dashboard").then(({ data }) => {
                    this.requests = (data.data.waiting + data.data.penning);
                    this.approved = data.data.approved;
                    this.rejected = data.data.rejected;
                });
            },
            loadEmployees(){
              axios.get("/api/employees/list").then(({ data }) => (this.employees = data.data));
            },
            loadLeaveTypes(){
              axios.get("/api/leaveTypes/list").then(({ data }) => (this.leaveTypes = data.data));
            },
        },
        mounted() {
            //console.log('Component mounted.')
        }
        ,created() {
            this.date_form = new Date().toISOString().slice(0,8) + '01';
            this.date_to = new Date().toISOString().slice(0,10);

            this.$Progress.start();
            this.loadEmployees();
            this.loadDashboard();
            this.loadLeaves();
            this.loadLeaveTypes();
            this.$Progress.finish();
        },
        filters: {
            moment: function (date) {
                return moment(date).format('DD MMM YYYY');
            },
            moment2: function (date) {
                return moment(date).format('YYYY-MM-DD');
            }
        }
    }
</script>
