<template>
    <section class="content">
        <div class="container-fluid">

            <div class="row">
                <!-- Left col -->
                <div class="col-md-12">
                    <!-- MAP & BOX PANE -->
                    
                    <div class="card">
                      <div class="card-header">
                          <h3 class="card-title">Evaluations</h3>
                      </div>
                      <!-- /.card-header -->
                      <div class="card-body p-0">
                          <div class="table-responsive">
                          <table class="table m-0">
                              <thead>
                                  <tr>
                                      <th width="100">Year</th>
                                      <th width="200">Employee</th>
                                      <th>Description</th>
                                      <th width="150">Status</th>
                                  </tr>
                              </thead>
                              <tbody v-if="evaluations.data?.length > 0">
                                  <tr v-for="evaluation in evaluations.data" :key="evaluation.id">
                                      <td>{{evaluation.eva_year}}</td>
                                      <td>
                                        {{evaluation.employee.title}} {{evaluation.employee.firstname}} {{ evaluation.employee.lastname }}
                                      </td>
                                      <td><a v-bind:href="'/evaluations/view/'+ evaluation.id">แบบประเมินผลการปฏิบัติงานพนักงาน<br/>(Employee Performance Evaluation Form)</a></td>
                                      <td>
                                        <span v-show="evaluation.step == '1'" class="badge badge-warning p-10">Pending</span>
                                        <span v-show="evaluation.step == '2'" class="badge badge-success p-10">HOD Review</span>
                                        <span v-show="evaluation.step == '3'" class="badge badge-success p-10">GM Review</span>
                                        <span v-show="evaluation.step == '4'" class="badge badge-success p-10">MD Review</span>
                                        <span v-show="evaluation.step == '5'" class="badge badge-success p-10">Completed</span>
                                      </td>
                                  </tr>
                              </tbody>
                              <tbody v-if="evaluations.data?.length === 0">
                                  <tr>
                                      <td colspan="4">No Data</td>
                                  </tr>
                              </tbody>
                          </table>
                          </div>
                      </div>
                  <!-- /.card-body -->
                      <div class="card-footer">
                      <div class="row">
                          <div class="col-md-6" style="padding-top:5px">Showing {{evaluations.from}} to {{evaluations.to}} of {{evaluations.total}} entries</div>
                          <div class="col-md-6">
                              <pagination :data="evaluations" :limit="5" :keepLength="true" @pagination-change-page="getResults" align="right">
                                <span slot="prev-nav">&lt; Previous</span>
	                              <span slot="next-nav">Next &gt;</span>
                              </pagination>
                          </div>
                      </div>
                    </div>
                    </div>
                <!-- /.card -->
                <!-- /.card -->
                </div>
            <!-- /.col -->
            </div>
            <!-- /.row -->
        </div><!--/. container-fluid -->
    </section>
</template>

<script>
    import moment from 'moment';

    export default {
        data () {
            return  {
                rule: '',
                evaluations : {},
                requests: 0,
                approved: 0,
                rejected: 0,
                employees: {},
                employee_id: '',
                evaluationTypes: {},
                evaluation_type_id: ''
            }
        },
        methods : {
            getResults(page = 1) {
              let status = this.$route.query.status;
                if(status == undefined) {
                  status = 'all';
                }

              this.$Progress.start();
              axios.get('/api/evaluations/list?page=' + page +'&status='+status +'&employee_id='+this.employee_id+'&evaluation_type_id='+this.evaluation_type_id).then(({ data }) => (this.evaluations = data.data));
              this.$Progress.finish();
            },
            loadEvaluations(){
                axios.get("/api/evaluations").then(({ data }) => {
                    this.evaluations = data.data;
                });
            }
        },
        mounted() {
            //console.log('Component mounted.')
        }
        ,created() {
            this.$Progress.start();
            this.loadEvaluations();
            this.$Progress.finish();
        },
        filters: {
            moment: function (date) {
                return moment(date).format('DD MMM YYYY');
            },
            moment2: function (date) {
                return moment(date).format('YYYY-MM-DD');
            }
        }
    }
</script>
