<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row ">
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon bg-success elevation-1"><i class="fas fa-user"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-number">
                            {{this.requests}}
                            </span>
                            <span class="info-box-text">Employees</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->
                <div class="col-12 col-sm-6 col-md-3" v-show="$gate.isAdmin() || $gate.isManager()">
                    <div class="info-box">
                        <span class="info-box-icon bg-info elevation-1"><i class="fas fa-user"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-number">
                            {{this.total_probations}}
                            </span>
                            <span class="info-box-text">Probations</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
            </div>
            <!-- /.row -->

            <div class="row">
                <!-- Left col -->
                <div class="col-md-8">
                    <!-- MAP & BOX PANE -->
                    <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">All Employees</h3>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body table-responsive p-0">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>&nbsp;</th>
                                    <th>Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="employee in employees.data" :key="employee.id">
                                    <td align="center">
                                        {{employee.code}}<br/>
                                        <img v-if="employee.avatar !== '' && employee.avatar !== null" :src="get_avatar(employee)" style="width:100px;height:100px"/>
                                    </td>
                                    <td>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <b>{{employee.title}} {{employee.firstname}} {{employee.lastname}} ({{employee.nickname}})</b>
                                            </div>
                                            <div class="col-sm-6"><label class="control-label">Position:</label> <span v-if="employee.position != undefined">{{employee.position.name}}</span></div>
                                            <div class="col-sm-6"><label class="control-label">Department:</label>  <span v-if="employee.department != undefined">{{employee.department.name}}</span></div>
                                            <div class="col-sm-12"><label class="control-label">Email:</label> {{employee.email}}</div>
                                            <div class="col-sm-12"><label class="control-label">Telephone:</label> {{employee.telephone}}</div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                <!-- /.card-body -->
                        <div class="card-footer">
                        <pagination :data="employees" @pagination-change-page="getResults"></pagination>
                    </div>
                    </div>
                <!-- /.card -->
                <!-- /.card -->
                </div>
            <!-- /.col -->
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Announcements</h3>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover m-0">
                                <tbody>
                                    <tr v-for="announcement in announcements.data" :key="announcement.id">
                                        <td>
                                             <a v-bind:href="'/upload/'+ announcement.link" target="_blank">
                                            {{announcement.name}}
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- /.card-body -->
                    </div>

                    <div class="card" v-show="$gate.isAdmin() || $gate.isManager()" v-if="probations?.length > 0">
                        <div class="card-header">
                            <h3 class="card-title">Probations</h3>
                        </div>
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover m-0">
                                <tbody>
                                    <tr v-for="probation in probations" :key="probation.id">
                                        <td>
                                            {{probation.firstname}} {{probation.lastname}}
                                        </td>
                                        <td align="right">
                                            {{probation.probation_date | moment}}
                                        </td>
                                    </tr>
                                    <tr>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Leaves</h3>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover m-0">
                                <tbody>
                                    <tr v-for="leave in leaves" :key="leave.id">
                                        <td style="width:60%">
                                            {{leave.employee.nickname}}
                                            <br/>
                                            {{leave.start_date | moment1}} - {{leave.end_date | moment}}
                                        </td>
                                        <td align="right">
                                            {{leave.leave_type.name}}<br>{{leave.days}} days.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- /.card-body -->
                        
                    </div>
                </div>
            </div>
            <!-- /.row -->
        </div><!--/. container-fluid -->
    </section>
</template>

<script>
    import moment from 'moment'

    export default {
        data () {
            return  {
                rule: '',
                employees : {},
                announcements : {},
                leaves : {},
                probations : {},
                requests: 0,
                approved: 0,
                rejected: 0,
                total_probations: 0,
            }
        },
        methods : {
            getResults(page = 1) {
              this.$Progress.start();
              axios.get('/api/employees?page=' + page).then(({ data }) => (this.employees = data.data));
              this.$Progress.finish();
            },
            getAnnouncements(page = 1) {
              this.$Progress.start();
              axios.get('/api/announcements?page=' + page).then(({ data }) => (this.announcements = data.data));
              this.$Progress.finish();
            },
            getLeaves(page = 1) {
              this.$Progress.start();
              axios.get('/api/leaves/approved?page=' + page).then(({ data }) => (this.leaves = data.data));
              this.$Progress.finish();
            },
            getProbations(page = 1) {
              this.$Progress.start();
              axios.get('/api/employees/probations?page=' + page).then(({ data }) => (this.probations = data.data));
              this.$Progress.finish();
            },
            loadEmployees(){
                axios.get("/api/employees").then(({ data }) => {
                    this.employees = data.data;
                    this.requests = data.data.total;
                });
            },
            loadAnnouncements(){
                axios.get("/api/announcements").then(({ data }) => {
                    this.announcements = data.data;
                });
            }
            , get_avatar(emp){
               let photo = "/images/profile/"+ emp.avatar;
               return photo;
            },
            loadApprovedLeave(){
                axios.get("/api/leaves/approved").then(({ data }) => {
                    this.leaves = data.data;
                });
            },
            loadProbations(){
                axios.get("/api/employees/probations").then(({ data }) => {
                    this.probations = data.data;
                    this.total_probations = Object.keys(this.probations).length;
                });
            }
        },
        mounted() {
            //console.log('Component mounted.')
        }
        ,created() {
            this.$Progress.start();
            this.loadEmployees();
            this.loadProbations();
            this.loadAnnouncements();
            this.loadApprovedLeave();
            this.$Progress.finish();
        },
        filters: {
            moment: function (date) {
                return moment(date).format('DD MMM YYYY');
            },
            moment1: function (date) {
                return moment(date).format('DD MMM');
            },
            moment2: function (date) {
                return moment(date).format('YYYY-MM-DD');
            }
        }
    }
</script>
