<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Customers</h3>

                <div class="card-tools">
                  <button type="button" class="btn btn-sm btn-primary">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Code</th>
                      <th>Name</th>
                      <th>Telephone</th>
                      <th>Email</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="customer in customers.data" :key="customer.id">
                      <td>{{customer.id}}</td>
                      <td>{{customer.code}}</td>
                      <td>{{customer.name}}</td>
                      <td>{{customer.email}}</td>
                      <td>{{customer.phone}}</td>
                      <td>
                        <a href="#">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        /
                        <a href="#">
                            <i class="fa fa-trash red"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="customers" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div v-if="!$gate.isAdmin()">
            <not-found></not-found>
        </div>
    </div>
  </section>
</template>

<script>
    import DatePicker from 'vue2-datepicker';

    export default {
        components: {
          DatePicker,
        },
        data () {
            return {
                customers : {},
            }
        },
        methods: {
            getResults(page = 1) {
                  this.$Progress.start();
                  axios.get('/api/customers/all?page=' + page).then(({ data }) => (this.customers = data.data));
                  this.$Progress.finish();
            },
            editModal(customer){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(customer);
            },
            deleteCustomer(id){
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {

                        // Send request to the server
                         if (result.value) {
                                this.form.delete('/api/customers/'+id).then(()=>{
                                        Swal.fire(
                                        'Deleted!',
                                        'Your file has been deleted.',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.loadCustomers();
                                }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                         }
                    })
            },
            loadCustomers(){
                this.$Progress.start();

                if(this.$gate.isAdmin()){
                axios.get("/api/customers").then(({ data }) => (this.customers = data.data));
                }

                this.$Progress.finish();
            }
        },
        mounted() {
        },
        created() {

            this.$Progress.start();
            this.loadCustomers();
            this.$Progress.finish();
        }
    }
</script>
