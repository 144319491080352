<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-sm-6 col-md-3">
                <div class="info-box">
                    <span class="info-box-icon bg-success elevation-1"><i class="fas fa-user"></i></span>
                    <div class="info-box-content">
                        <span class="info-box-number">
                        {{this.employees.length}}
                        </span>
                        <span class="info-box-text">Actives</span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
                <div class="info-box">
                    <span class="info-box-icon bg-warning elevation-1"><i class="fas fa-user"></i></span>
                    <div class="info-box-content">
                        <span class="info-box-number">
                        {{this.probations.length}}
                        </span>
                        <span class="info-box-text">Probations</span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
                <div class="info-box">
                    <span class="info-box-icon bg-danger elevation-1"><i class="fas fa-user"></i></span>
                    <div class="info-box-content">
                        <span class="info-box-number">
                        {{this.resigned.length}}
                        </span>
                        <span class="info-box-text">Resigned</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card" v-if="$gate.isAdmin()">
              <div class="card-header">
                <h3 class="card-title">Employees</h3>

                <div class="card-tools">
                  
                  <!--
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>
                  -->
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Email / Mobile</th>
                      <th>Position</th>
                      <th>Department</th>
                      <th width="100px">Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="user in users.data" :key="user.id">
                      <td>{{user.code}}</td>
                      <td class="text-capitalize">{{user.title}} {{user.firstname}} {{user.lastname}} ({{user.nickname}})</td>
                      <td>{{user.email}}<br/>{{user.mobile}}</td>
                      <td class="text-capitalize"><span v-if="user.position">{{user.position.name}}</span></td>
                      <td class="text-capitalize"><span v-if="user.department">{{user.department.name}}</span></td>
                      <td class="text-capitalize">
                        <span v-show="user.status_desc == 'A'" class="badge badge-success">Active</span>
                        <span v-show="user.status_desc == 'R'" class="badge badge-danger">Resigned</span>
                        <span v-show="user.status_desc == 'P'" class="badge badge-warning">Probation</span>
                      </td>
                      <td>
                        <a href="#" @click="editModal(user)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        /
                        <a href="#" @click="deleteUser(user.id)">
                            <i class="fa fa-trash red"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <div class="row">
                    <div class="col-md-6" style="padding-top:5px">Showing {{users.from}} to {{users.to}} of {{users.total}} entries</div>
                    <div class="col-md-6">
                        <pagination :data="users" @pagination-change-page="getResults" align="right"></pagination>
                    </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
         
        </div>


        <div v-if="!$gate.isAdmin()">
            <not-found></not-found>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New User</h5>
                    <h5 class="modal-title" v-show="editmode">Update User's Info</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="editmode ? updateUser() : createUser()">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>Company</label>
                                    <select name="company_id" v-model="form.company_id" id="company_id" class="form-control" :class="{ 'is-invalid': form.errors.has('company_id') }">
                                        <option v-for="(cat,index) in companies" :key="index" :value="index" :selected="index == form.company_id">{{ cat }}</option>
                                    </select>
                                    <has-error :form="form" field="company_id"></has-error>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>Employee ID</label>
                                    <input v-model="form.code" type="text" name="code"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('code') }">
                                    <has-error :form="form" field="code"></has-error>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>Title</label>
                                    <select name="title" v-model="form.title" id="title" class="form-control" :class="{ 'is-invalid': form.errors.has('title') }">
                                        <option value="">Select Title</option>
                                        <option value="Mr.">Mr.</option>
                                        <option value="Mrs.">Mrs.</option>
                                        <option value="Ms.">Ms.</option>
                                    </select>
                                    <has-error :form="form" field="title"></has-error>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>Nick name</label>
                                    <input v-model="form.nickname" type="text" name="nickname"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('nickname') }">
                                    <has-error :form="form" field="nickname"></has-error>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>First name</label>
                                    <input v-model="form.firstname" type="text" name="firstname"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('firstname') }">
                                    <has-error :form="form" field="firstname"></has-error>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>Last name</label>
                                    <input v-model="form.lastname" type="text" name="lastname"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('lastname') }">
                                    <has-error :form="form" field="lastname"></has-error>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>Email</label>
                                    <input v-model="form.email" type="text" name="email"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('email') }">
                                    <has-error :form="form" field="email"></has-error>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>Telephone</label>
                                    <input v-model="form.telephone" type="text" name="telephone"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('telephone') }">
                                    <has-error :form="form" field="telephone"></has-error>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>Position</label>
                                    <select name="position_id" v-model="form.position_id" id="position_id" class="form-control" :class="{ 'is-invalid': form.errors.has('position_id') }">
                                        <option :value={}>Please select one</option>
                                        <option v-for="(cat,index) in positions" :key="index" :value="index" :selected="index == form.position_id">{{ cat }}</option>
                                    </select>
                                    <has-error :form="form" field="position_id"></has-error>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>Department</label>
                                    <select name="department_id" v-model="form.department_id" id="department_id" class="form-control" :class="{ 'is-invalid': form.errors.has('department_id') }">
                                        <option :value={}>Please select one</option>
                                        <option v-for="(cat,index) in departments" :key="index" :value="index" :selected="index == form.department_id">{{ cat }}</option>
                                    </select>
                                    <has-error :form="form" field="department_id"></has-error>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>Manager / Supervisor</label>
                                    <select class="form-control" v-model="form.manager_id">
                                    <option 
                                        v-for="(cat,index) in managers" :key="index"
                                        :value="cat.id"
                                        :selected="index == form.manager_id">{{ cat.firstname }} {{ cat.lastname }}</option>
                                    </select>
                                    <has-error :form="form" field="manager_id"></has-error>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>Date of Emp</label>
                                    <date-picker v-model="form.start_date" valueType="format"></date-picker>
                                    <has-error :form="form" field="start_date"></has-error>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>Status</label>
                                    <select name="status" v-model="form.status" id="status" class="form-control" :class="{ 'is-invalid': form.errors.has('status') }">
                                        <option value="1">Active</option>
                                        <option value="9">Resigned</option>
                                    </select>
                                    <has-error :form="form" field="start_date"></has-error>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>Date of resigned</label>
                                    <date-picker v-model="form.end_date" valueType="format"></date-picker>
                                    <has-error :form="form" field="end_date"></has-error>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="modal-footer">
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import moment from 'moment'

    export default {
        components: {
          DatePicker,
        },
        data () {
            return {
                editmode: false,
                users : {},
                form: new Form({
                    id : '',
                    code: '',
                    title: '',
                    nickname : '',
                    firstname : '',
                    lastname: '',
                    email: '',
                    telephone: '',
                    password: '',
                    email_verified_at: '',
                    company_id: '',
                    department_id: '',
                    position_id : '',
                    manager_id : '',
                    user: {},
                    start_date : '',
                    end_date : '',
                    status: 1,
                }),
                departments : {},
                positions : {},
                companies : {},
                managers : {},
                emp_status: [],
            }
        },
        methods: {
            getResults(page = 1) {

                  this.$Progress.start();
                  
                  axios.get('/api/employees/all?page=' + page).then(({ data }) => (this.users = data.data));

                  this.$Progress.finish();
            },
            updateUser(){
                this.$Progress.start();
                // console.log('Editing data');
                this.form.put('/api/employees/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');

                    this.loadUsers();
                })
                .catch(() => {
                    this.$Progress.fail();
                });

            },
            editModal(user){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(user);
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },
            deleteUser(id){
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {

                        // Send request to the server
                         if (result.value) {
                                this.form.delete('/api/employees/'+id).then(()=>{
                                        Swal.fire(
                                        'Deleted!',
                                        'Your file has been deleted.',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.loadUsers();
                                }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                         }
                    })
            },
            loadUsers(){
                this.$Progress.start();

                if(this.$gate.isAdmin()){
                    axios.get("/api/employees/all").then(({ data }) => (this.users = data.data));
                }

                this.$Progress.finish();
            },
            loadDepartments(){
                axios.get("/api/departments/list").then(({ data }) => (this.departments = data.data));
            },
            loadPositions(){
                axios.get("/api/positions/list").then(({ data }) => (this.positions = data.data));
            },
            loadCompanies(){
                axios.get("/api/companies/list").then(({ data }) => (this.companies = data.data));
            },
            loadManagers(){
              axios.get("/api/employees/list").then(({ data }) => (this.managers = data.data));
            },
          
            createUser(){

                this.form.post('/api/employees')
                .then((response)=>{
                    $('#addNew').modal('hide');

                    Toast.fire({
                            icon: 'success',
                            title: response.data.message
                    });

                    this.$Progress.finish();
                    this.loadUsers();

                })
                .catch(()=>{

                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            }

        },
        computed: {
            resigned() {
                let tempRecipes = this.emp_status;
                tempRecipes = tempRecipes.filter((item) => {
                    return (item.status=='9')
                })
    
                return tempRecipes;
            },
            employees() {
                let tempRecipes = this.emp_status;
                tempRecipes = tempRecipes.filter((item) => {
                    return (item.status=='1')
                })
    
                return tempRecipes;
            },
            probations() {
                let tempRecipes = this.emp_status;
                tempRecipes = tempRecipes.filter((item) => {
                    return (item.days < 120 && item.status=='1')
                })
    
                return tempRecipes;
            },
            contacts() {
                let tempRecipes = this.emp_status;
                tempRecipes = tempRecipes.filter((item) => {
                    return (item.type=='Contact');
                })
    
                return tempRecipes;
            },
            notify_contacts() {
                let tempRecipes = this.emp_status;
                tempRecipes = tempRecipes.filter((item) => {
                    return (item.remain_days <= 45 && item.type=='Contact');
                })
    
                return tempRecipes;
            },
            end_contacts() {
                let tempRecipes = this.emp_status;
                tempRecipes = tempRecipes.filter((item) => {
                    return (item.remain_days <= 0 && item.type=='Contact');
                })
    
                return tempRecipes;
            }
        },
        filters : {
            moment2: function (date) {
                return moment(date).format('DD-MM-YYYY');
            }
        },
        mounted() {
            console.log('User Component mounted.')
        },
        created() {

            this.$Progress.start();
            this.loadUsers();
            this.loadCompanies();
            this.loadPositions();
            this.loadDepartments();
            this.loadManagers();

            axios.get("/api/employees/status").then(({ data }) => (this.emp_status = data.data));

            this.$Progress.finish();
        }
    }
</script>

<style scoped>
    .pagination{
        margin-bottom: 0;
    }
</style>