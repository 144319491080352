<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                          <date-picker v-model="date_form" valueType="format" placeholder="From date"></date-picker>
                      </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                          <date-picker v-model="date_to" valueType="format" placeholder="To date"></date-picker>
                      </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                         <select class="form-control" v-model="employee_id">
                            <option value="">Select Employee</option>
                              <option 
                                  v-for="(cat,index) in employees" :key="index"
                                  :value="cat.id"
                                  :selected="index == employee_id">{{ cat.firstname }} {{ cat.lastname }}</option>
                            </select>
                      </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                          <button type="button" class="btn btn-primary" @click="searchRosters">
                            <i class="fa fa-search"></i>
                            Search
                          </button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Roster List</h3>

                <div class="card-tools">
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>
                  <button type="button" class="btn btn-sm btn-danger" @click="deleteSelected">
                      <i class="fa fa-trash"></i>
                      Delete
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>
                        <label class="form-checkbox">
                        <input type="checkbox" v-model="selectAll" @click="select">
                        <i class="form-icon"></i>
                        </label>
                      </th>
                      <th>Date</th>
                      <th>Employee</th>
                      <th>Shift</th>
                      <th style="width:100px">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="roster in rosters.data" :key="roster.id">
                      <td>
                        <label class="form-checkbox">
                          <input type="checkbox" :value="roster.id" v-model="selected">
                        <i class="form-icon"></i>
                        </label>
                      </td>
                      <td class="text-capitalize">{{roster.date}}</td>
                      <td>{{roster.employee.firstname}} {{roster.employee.lastname}}</td>
                      <td>{{roster.shift.name}}: {{roster.shift.start_time}}-{{roster.shift.end_time}}</td>
                      <td>
                        <a href="#" @click="editModal(roster)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        <a href="#" @click="delleteModal(roster)">
                            <i class="fa fa-trash red"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="rosters" @pagination-change-page="getResults" :limit="10"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>


        <div v-if="!$gate.isAdmin()">
            <not-found></not-found>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New Roster</h5>
                    <h5 class="modal-title" v-show="editmode">Update Roster</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="editmode ? updateRoster() : createRoster()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Date</label>
                            <date-picker v-model="form.date" valueType="format"></date-picker>
                            <has-error :form="form" field="date"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Shift</label>
                            <select class="form-control" v-model="form.shift_id">
                              <option 
                                  v-for="(cat,index) in shifts" :key="index"
                                  :value="cat.id"
                                  :selected="index == form.shift_id">{{ cat.name }} ({{ cat.start_time }}-{{ cat.end_time }})</option>
                            </select>
                            <has-error :form="form" field="shift_id"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Employee</label>
                            <select class="form-control" v-model="form.employee_id">
                              <option 
                                  v-for="(cat,index) in employees" :key="index"
                                  :value="cat.id"
                                  :selected="index == form.employee_id">{{ cat.firstname }} {{ cat.lastname }}</option>
                            </select>
                            <has-error :form="form" field="employee_id"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    import DatePicker from 'vue2-datepicker';

    export default {
        components: {
          DatePicker,
        },
        data () {
            return {
                editmode: false,
                rosters : {},
                form: new Form({
                    id : '',
                    date: '',
                    shift_id: '',
                    employee_id: '',
                }),
                shifts: [],
                employees: [],
                selected: [],
                selectAll: false,
                date_form: '',
                date_to: '',
                employee_id: '',
            }
        },
        methods: {
            searchRosters() {
              this.loadRosters();
            },
            getResults(page = 1) {
                  this.selectAll = false;
                  this.$Progress.start();
                  axios.get('/api/rosters?page=' + page + '&date_from='+this.date_form+'&date_to='+this.date_to+'&emp_id='+this.employee_id).then(({ data }) => (this.rosters = data.data));
                  this.$Progress.finish();
            },
            updateRoster(){
                this.$Progress.start();
                this.form.put('/api/rosters/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                    //  Fire.$emit('AfterCreate');

                    this.loadRosters();
                })
                .catch(() => {
                    this.$Progress.fail();
                });

            },
            editModal(roster){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(roster);
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },

            loadRosters(){
                this.selectAll = false;
                if(this.$gate.isAdmin()){
                    axios.get("/api/rosters?date_from="+this.date_form+'&date_to='+this.date_to+'&emp_id='+this.employee_id).then(({ data }) => (this.rosters = data.data));
                }
            },
            loadShifts(){
              axios.get("/api/shifts/list").then(({ data }) => (this.shifts = data.data));
            },
            loadEmployees(){
              axios.get("/api/employees/list").then(({ data }) => (this.employees = data.data));
            },
            
            createRoster(){
                this.form.post('/api/rosters')
                .then((response)=>{
                    $('#addNew').modal('hide');

                    Toast.fire({
                            icon: 'success',
                            title: response.data.message
                    });

                    this.$Progress.finish();
                    this.loadRosters();
                })
                .catch(()=>{
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            },
            select() {
              this.selected = [];
              if (!this.selectAll) {
                for (let i in this.rosters.data) {
                  this.selected.push(this.rosters.data[i].id);
                }
              }
            }, 
            deleteSelected() {
              let obj = {
                rosterIds: this.selected
              }
              axios.post('/api/rosters/delall', obj).then(res => {
                    this.loadRosters();
              });
            }
        },
        mounted() {
            console.log('Component mounted.')
        },
        created() {
            this.date_form = new Date().toISOString().slice(0,8) + '01';
            this.date_to = new Date().toISOString().slice(0,10);

            this.$Progress.start();
            this.loadRosters();
            this.loadShifts();
            this.loadEmployees();
            this.$Progress.finish();
        }
    }
</script>
