<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card" v-if="$gate.isAdmin()">
              <div class="card-header">
                <h3 class="card-title">Shift List</h3>

                <div class="card-tools">
                  
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Start</th>
                      <th>End</th>
                      <th>Allow Late</th>
                      <th>Allow Early</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="shift in shifts.data" :key="shift.id">

                      <td>{{shift.id}}</td>
                      <td class="text-capitalize">{{shift.name}}</td>
                      <td>{{shift.start_time}}</td>
                      <td>{{shift.end_time}}</td>
                      <td>{{shift.allow_late}}</td>
                      <td>{{shift.allow_early}}</td>
                      <td>
                        <a href="#" @click="editModal(shift)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        <a href="#" @click="deleteShift(shift.id)">
                            <i class="fa fa-trash red"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="shifts" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>


        <div v-if="!$gate.isAdmin()">
            <not-found></not-found>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New Shift</h5>
                    <h5 class="modal-title" v-show="editmode">Update Shift</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="editmode ? updateShift() : createShift()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Name</label>
                            <input v-model="form.name" type="text" name="name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                            <has-error :form="form" field="name"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Start</label>
                            <input v-model="form.start_time" type="text" name="start_time"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('start_time') }">
                            <has-error :form="form" field="start_time"></has-error>
                        </div>
                        <div class="form-group">
                            <label>End</label>
                            <input v-model="form.end_time" type="text" name="end_time"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('end_time') }">
                            <has-error :form="form" field="end_time"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Allow Late</label>
                            <input v-model="form.allow_late" type="text" name="allow_late"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('allow_late') }">
                            <has-error :form="form" field="allow_late"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Allow Early</label>
                            <input v-model="form.allow_early" type="text" name="allow_early"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('allow_early') }">
                            <has-error :form="form" field="allow_early"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    export default {
        data () {
            return {
                editmode: false,
                shifts : {},
                form: new Form({
                    id : '',
                    name: '',
                    start_time: '',
                    end_time: '',
                    allow_late: '',
                    allow_early: '',
                })
            }
        },
        methods: {

            getResults(page = 1) {

                  this.$Progress.start();
                  
                  axios.get('/api/shifts?page=' + page).then(({ data }) => (this.shifts = data.data));

                  this.$Progress.finish();
            },
            updateShift(){
                this.$Progress.start();
                this.form.put('/api/shifts/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                    //  Fire.$emit('AfterCreate');

                    this.loadShifts();
                })
                .catch(() => {
                    this.$Progress.fail();
                });

            },
            editModal(shift){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(shift);
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },

            loadShifts(){
                if(this.$gate.isAdmin()){
                    axios.get("/api/shifts").then(({ data }) => (this.shifts = data.data));
                }
            },
            
            createShift(){

                this.form.post('/api/shifts')
                .then((response)=>{
                    $('#addNew').modal('hide');

                    Toast.fire({
                            icon: 'success',
                            title: response.data.message
                    });

                    this.$Progress.finish();
                    this.loadShifts();
                })
                .catch(()=>{
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            },
            deleteShift(id){
              Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this!",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Yes, delete it!'
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('/api/shifts/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted!',
                                      'Your file has been deleted.',
                                      'success'
                                      );
                                  this.loadShifts();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },

        },
        mounted() {
            console.log('Component mounted.')
        },
        created() {

            this.$Progress.start();
            this.loadShifts();
            this.$Progress.finish();
        }
    }
</script>
