
<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header p-2">
                            <ul class="nav nav-pills">
                                <li class="nav-item"><a class="nav-link active show" href="#activity" data-toggle="tab">Profile</a></li>
                                <li class="nav-item"><a class="nav-link" href="#settings" data-toggle="tab">Settings</a></li>
                                <li class="nav-item"><a class="nav-link" href="#change-password" data-toggle="tab">Change Password</a></li>
                            </ul>
                        </div><!-- /.card-header -->
                        <div class="card-body">
                            <div class="tab-content">
                                <!-- Activity Tab -->
                                <div class="tab-pane active show" id="activity">
                                    <form  @submit.prevent="updateEmployee">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <label class="control-label">Employee ID:</label> {{form_emp.code}}
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="control-label">Start:</label> {{form_emp.start_date}}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <div class="avatar img-fluid img-circle" style="margin-top:10px">
                                                            <img v-if="form_emp.avatar !== null && form_emp.avatar !== ''" :src="get_avatar()" style="width:150px;height:150px"/>
                                                        </div>
                                                        <br>
                                                        <input type="file" @change='upload_avatar' :class="{ 'is-invalid': form_emp.errors.has('avatar') }" name="avatar">
                                                        <has-error :form="form_emp" field="avatar"></has-error> 
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-8">
                                                <div class="row m-3">
                                                    <div class="col-sm-6">
                                                        <label for="title" class="control-label">Title</label>
                                                        <select name="title" v-model="form_emp.title" id="title" class="form-control" :class="{ 'is-invalid': form_emp.errors.has('title') }">
                                                            <option value="">Select Title</option>
                                                            <option value="Mr.">Mr.</option>
                                                            <option value="Mrs.">Mrs.</option>
                                                            <option value="Ms.">Ms.</option>
                                                        </select>
                                                        <has-error :form="form_emp" field="title"></has-error>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <label for="nickname" class="control-label">Nickname</label>
                                                        <input type="" v-model="form_emp.nickname" class="form-control" id="nickname" placeholder="Nickname" :class="{ 'is-invalid': form_emp.errors.has('nickname') }">
                                                        <has-error :form="form_emp" field="nickname"></has-error>
                                                    </div>
                                                </div>

                                                <div class="row m-3">
                                                    <div class="col-sm-6">
                                                        <label for="firstname" class="control-label">First name</label>
                                                        <input type="" v-model="form_emp.firstname" class="form-control" id="firstname" placeholder="First name" :class="{ 'is-invalid': form_emp.errors.has('firstname') }">
                                                        <has-error :form="form_emp" field="firstname"></has-error>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <label for="lastname" class="control-label">Last name</label>
                                                        <input type="" v-model="form_emp.lastname" class="form-control" id="lastname" placeholder="Last name" :class="{ 'is-invalid': form_emp.errors.has('lastname') }">
                                                        <has-error :form="form_emp" field="lastname"></has-error>
                                                    </div>
                                                </div>
                                                <div class="row m-3">
                                                    <div class="col-sm-6">
                                                        <label class="control-label">Position</label>
                                                        <select class="form-control" v-model="form_emp.position_id">
                                                            <option 
                                                                v-for="(cat,index) in positions" :key="index"
                                                                :value="index"
                                                                :selected="index == form_emp.position_id">{{ cat }}</option>
                                                        </select>
                                                        <has-error :form="form_emp" field="position_id"></has-error>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <label class="control-label">Department</label>
                                                        <select class="form-control" v-model="form_emp.department_id">
                                                        <option 
                                                            v-for="(cat,index) in departments" :key="index"
                                                            :value="index"
                                                            :selected="index == form_emp.department_id">{{ cat }}</option>
                                                        </select>
                                                        <has-error :form="form_emp" field="department_id"></has-error>
                                                    </div>
                                                </div>

                                                <div class="row m-3">
                                                    <div class="col-sm-6">
                                                        <label for="email" class="control-label">Email</label>
                                                        <input type="" v-model="form_emp.email" class="form-control" id="email" placeholder="Email" :class="{ 'is-invalid': form_emp.errors.has('email') }">
                                                        <has-error :form="form_emp" field="email"></has-error>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <label for="telephone" class="control-label">Telephone</label>
                                                        <input type="" v-model="form_emp.telephone" class="form-control" id="telephone" placeholder="Telephone" :class="{ 'is-invalid': form_emp.errors.has('telephone') }">
                                                        <has-error :form="form_emp" field="telephone"></has-error>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row m-3">
                                            <div class="col-sm-12 text-right">
                                                <button type="submit" class="btn btn-success">Update Profile</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <!-- Setting Tab -->
                                <div class="tab-pane" id="settings">
                                    <form @click.prevent="updateInfo" class="form-horizontal">
                                        <div class="form-group">
                                            <label for="inputName" class="col-sm-2 control-label">Name</label>

                                            <div class="col-sm-12">
                                            <input type="" v-model="form.name" class="form-control" id="inputName" placeholder="Name" :class="{ 'is-invalid': form.errors.has('name') }">
                                            <has-error :form="form" field="name"></has-error>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="inputEmail" class="col-sm-2 control-label">Email</label>

                                            <div class="col-sm-12">
                                                <input type="email" v-model="form.email" class="form-control" id="inputEmail" placeholder="Email"  :class="{ 'is-invalid': form.errors.has('email') }">
                                                <has-error :form="form" field="email"></has-error>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="col-md-12">
                                                <button type="submit" class="btn btn-success">Update Profile</button>
                                                <div class="float-right d-sm-inline text-muted">Member since: <span>{{form.created_at|myDate}}</span></div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <!-- Setting Tab -->
                                <div class="tab-pane" id="change-password">
                                    <form class="form-horizontal">
                                    <div class="form-group">
                                        <label for="current_password" class="col-sm-2 control-label">Current Password</label>

                                        <div class="col-sm-12">
                                            <input type="password"
                                                v-model="form.current_password"
                                                class="form-control"
                                                id="current_password"
                                                placeholder="Current Password"
                                                :class="{ 'is-invalid': form.errors.has('current_password') }"
                                            >
                                            <has-error :form="form" field="current_password"></has-error>
                                        
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="new_password" class="col-sm-2 control-label">New Password</label>

                                        <div class="col-sm-12">
                                            <input type="password"
                                                v-model="form.new_password"
                                                class="form-control"
                                                id="new_password"
                                                placeholder="New Password"
                                                :class="{ 'is-invalid': form.errors.has('new_password') }"
                                            >
                                            <has-error :form="form" field="new_password"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="confirm_password" class="col-sm-2 control-label">Confirm Password</label>

                                        <div class="col-sm-12">
                                            <input type="password"
                                                v-model="form.confirm_password"
                                                class="form-control"
                                                id="confirm_password"
                                                placeholder="Confirm Password"
                                                :class="{ 'is-invalid': form.errors.has('confirm_password') }"
                                            >
                                            <has-error :form="form" field="confirm_password"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div class="col-sm-offset-2 col-sm-12">
                                        <button @click.prevent="updatePassword" type="submit" class="btn btn-success">Update Password</button>
                                        </div>
                                    </div>
                                    </form>
                                </div>
                            <!-- /.tab-pane -->
                            </div>
                            <!-- /.tab-content -->
                        </div><!-- /.card-body -->
                    </div>
                    <!-- /.nav-tabs-custom -->
            </div>
            <!-- end tabs -->
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        data(){
            return {
                 form: new Form({
                    id:'',
                    name : '',
                    email: '',
                    password: '',
                    created_at: ''
                }),
                form_emp: new Form({
                    id:'',
                    user_id : '',
                    code: '',
                    title: '',
                    nickname: '',
                    firstname: '',
                    lastname: '',
                    email: '',
                    telephone: '',
                    department_id: 0,
                    position_id: 0,
                    company_id: 0,
                    manager_id: 0,
                    start_date: '',
                    avatar: '',
                }),
                departments: {},
                positions : {},
                companies : {},
                managers : {}
            }
        },
        mounted() {
            console.log('Component mounted.')
        },
        methods:{
            updateInfo(){
                this.$Progress.start();
                if(this.form.password == ''){
                    this.form.password = undefined;
                }
                this.form.put('/api/profile')
                .then((data)=>{
                    this.$Progress.finish();
                    Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                })
                .catch((data) => {
                    this.$Progress.fail();

                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                });
            },

            updatePassword(){
                this.$Progress.start();
                this.form.post('/api/change-password')
                .then((data)=>{
                    //  Fire.$emit('AfterCreate');
                    this.$Progress.finish();
                    this.form.current_password = '';
                    this.form.new_password = '';
                    this.form.confirm_password = '';

                    Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                })
                .catch(() => {
                    this.$Progress.fail();

                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                });
            }, 
            updateEmployee() {
                this.form_emp.put('/api/employees/'+this.form_emp.id)
                .then((data)=>{
                    this.$Progress.finish();
                    Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                })
                .catch((data) => {
                    this.$Progress.fail();

                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                });
            },
            upload_avatar(e){
                let file = e.target.files[0];
                let reader = new FileReader();  

                if(file['size'] < 2111775)
                {
                    reader.onloadend = (file) => {
                     this.form_emp.avatar = reader.result;
                    }              
                     reader.readAsDataURL(file);
                }else{
                    alert('File size can not be bigger than 2 MB')
                }
            },
            get_avatar(){
               let photo = (this.form_emp.avatar.length > 100) ? this.form_emp.avatar : "/images/profile/"+ this.form_emp.avatar;
               return photo;
            },
            loadDepartments(){
              axios.get("/api/departments/list").then(({ data }) => (this.departments = data.data));
            },
            loadPositions(){
                axios.get("/api/positions/list").then(({ data }) => (this.positions = data.data));
            },
            loadCompanies(){
                axios.get("/api/companies/list").then(({ data }) => (this.companies = data.data));
            },
            loadManagers(){
              axios.get("/api/employees/list").then(({ data }) => (this.managers = data.data));
            },
        },

        created() {

            this.$Progress.start();

            axios.get("/api/profile")
            .then(({ data }) => {
                this.form.fill(data.data);
                this.form_emp.fill(data.employee);
                });

            this.loadDepartments();
            this.loadPositions();
            this.loadCompanies();
            this.loadManagers();
            
            this.$Progress.finish();
        }
    }
</script>
