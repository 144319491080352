<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row ">
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon bg-info elevation-1"><i class="fas fa-cog"></i></span>

                        <div class="info-box-content">
                            <span class="info-box-text">Request</span>
                            <span class="info-box-number">
                            {{this.requests}}
                            </span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon bg-success elevation-1"><i class="fas fa-thumbs-up"></i></span>

                        <div class="info-box-content">
                            <span class="info-box-text">Approved</span>
                            <span class="info-box-number">{{this.approved}}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->

                <!-- fix for small devices only -->
                <div class="clearfix hidden-md-up"></div>

                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon bg-danger elevation-1"><i class="fas fa-shopping-cart"></i></span>

                        <div class="info-box-content">
                            <span class="info-box-text">Rejected</span>
                            <span class="info-box-number">{{this.rejected}}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
            <!-- /.col -->
            </div>
            <!-- /.row -->

            <div class="row">
                <!-- Left col -->
                <div class="col-md-12">
                    <!-- MAP & BOX PANE -->
                    <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Leaves Request</h3>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body p-0">
                        <div class="table-responsive">
                        <table class="table m-0">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Leave Type</th>
                                    <th>Days</th>
                                    <th>Request</th>
                                    <th>Status</th>
                                    <th>Details</th>
                                </tr>
                            </thead>
                            <tbody v-if="leaves.data?.length > 0">
                                <tr v-for="leave in leaves.data" :key="leave.id">
                                    <td>{{leave.employee.firstname}} {{leave.employee.lastname}}</td>
                                    <td>
                                         <a v-bind:href="'/uploads/'+ leave.document_ref" v-if="leave.document_ref != null" target="_blank">
                                            <i class="fa fa-file fa-lg red" aria-hidden="true"></i>
                                        </a>
                                        {{leave.leave_type.name}}
                                        <br/>
                                        {{leave.start_date | moment}} to {{leave.end_date | moment}}
                                    </td>
                                    <td>{{leave.days}} Days</td>
                                    <td>{{leave.created_at | moment2}}</td>
                                    <td>
                                        <span v-show="leave.status == '00'" class="badge badge-info p-10">{{leave.leave_status.name}}</span>
                                        <span v-show="leave.status == '01'" class="badge badge-warning">{{leave.leave_status.name}}</span>
                                        <span v-show="leave.status == '02'" class="badge badge-warning">{{leave.leave_status.name}}</span>
                                        <span v-show="leave.status == '10'" class="badge badge-success">{{leave.leave_status.name}}</span>
                                        <span v-show="leave.status == '90'" class="badge badge-danger">{{leave.leave_status.name}}</span>
                                        <span v-show="leave.status == '91'" class="badge badge-success">{{leave.leave_status.name}}</span>
                                        <span v-show="leave.status == '92'" class="badge badge-success">{{leave.leave_status.name}}</span>
                                    </td>
                                    <td>
                                        <a v-show="$gate.isManager()" v-bind:href="'/leaves-approval/'+ leave.id">Details</a>
                                        <a v-show="$gate.isUser()" v-bind:href="'/leaves-approval/'+ leave.id">Details</a>
                                        <a v-show="$gate.isAdmin()" v-bind:href="'/leaves-approval/'+ leave.id">Details</a>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-if="leaves.data?.length === 0">
                                <tr>
                                    <td colspan="6">No Data</td>
                                </tr>
                            </tbody>
                            
                        </table>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-md-6" style="padding-top:5px">Showing {{leaves.from}} to {{leaves.to}} of {{leaves.total}} entries</div>
                            <div class="col-md-6">
                                <pagination :data="leaves" :limit="5" :keepLength="true" @pagination-change-page="getResults" align="right">
                                    <span slot="prev-nav">&lt; Previous</span>
                                    <span slot="next-nav">Next &gt;</span>
                                </pagination>
                            </div>
                        </div>
                    </div>
                <!-- /.card-body -->
                    </div>
                <!-- /.card -->
                <!-- /.card -->
                </div>
            <!-- /.col -->
            </div>
            <!-- /.row -->
        </div><!--/. container-fluid -->
    </section>
</template>

<script>
    import moment from 'moment'

    export default {
        data () {
            return  {
                rule: '',
                leaves : [],
                requests: 0,
                approved: 0,
                rejected: 0,
            }
        },
        methods : {
            getResults(page = 1) {
              this.$Progress.start();
              axios.get('/api/leaves/list?page=' + page).then(({ data }) => (this.leaves = data.data));
              this.$Progress.finish();
            },
            loadLeaves(){
                axios.get("/api/leaves/list").then(({ data }) => {
                    this.leaves = data.data;
                });
            },
            loadDashboard(){
                axios.get("/api/leaves/dashboard").then(({ data }) => {
                    this.requests = data.data.waiting;
                    this.approved = data.data.approved;
                    this.rejected = data.data.rejected;
                });
            }
        },
        mounted() {
            console.log('Component mounted.')
        }
        ,created() {
            this.$Progress.start();
            this.loadDashboard();
            this.loadLeaves();
            this.$Progress.finish();
        },
        filters: {
            moment: function (date) {
                return moment(date).format('DD MMMM YYYY');
            },
            moment2: function (date) {
                return moment(date).format('YYYY-MM-DD');
            }
        }
    }
</script>
